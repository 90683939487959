angular.module 'app'
.controller 'SmgRecordModal', ($scope, $http, $stateParams, $uibModalInstance, $timeout, $rootScope, config, dialogs, Storage, growl) ->

  $scope.ui =
    records: []
    currentPage: 1
    perPage: 10
    maxSize: 5
    totalItems: 0
    date: ''
    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#material-graphic'
  $scope.ui.opts.opens = 'right'
  
  do fetchData = ->
    _start_created_at = ''
    _end_created_at = ''

    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      _start_created_at = ''
      _end_created_at = ''
    else
      _start_created_at = moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
      _end_created_at = moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')

    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/smg/records?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        start_created_at: _start_created_at
        end_created_at: _end_created_at
    .then (res) ->
      _.each res.data.data, (val) ->
        val.createTime = moment(val.created_at).format('YYYY-MM-DD')
        total_count = _.findWhere val.records, key: 'total'
        val.total_count = total_count.count
        count100 = _.findWhere val.records, tag_id: 100
        val.count100 = count100.count
        count101 = _.findWhere val.records, tag_id: 101
        val.count101 = count101.count
        count102 = _.findWhere val.records, tag_id: 102
        val.count102 = count102.count
      $scope.ui.totalItems = res.data.count
      $scope.ui.records = res.data.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.currentPage', fetchData

  $scope.searchText = ->
    $scope.ui.totalItems = 0
    $scope.ui.records = []
    $scope.ui.currentPage = 1
    fetchData()

  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.progress = 0
  $scope.exportRecords = ->
    $scope.progress = 0

    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      _start_created_at = ''
      _end_created_at = ''
    else
      _start_created_at = moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
      _end_created_at = moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')

    _params =
      start_created_at: _start_created_at
      end_created_at: _end_created_at

    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/smg/export/?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data: _params
      prepareCallback: prepare
      successCallback: success
      failCallback: error

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
    
angular.module 'app'
.controller 'FreePublishCtrl', ($uibModalInstance, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert, data) ->

  $scope.ui =
    radio: data.radio
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    materials: []
    saveState: false
    search:
      text: ''

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
    $scope.$watch 'ui.currentPage', findDatas
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  findDatas = ->
    $scope.ui.isLoading = true

    _params =
      type: 'freepublish'
      original_id: $stateParams.original_id
      count: $scope.ui.perPage
      offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage

    $http.post "#{config.api.baseUrl}/material/get-materials?access_token=" + Storage.get('access_token'), _params
    .then (res) ->
      console.log res.data.item
      $scope.ui.totalItems = res.data.total_count
      $scope.ui.materials = res.data.item
      _.each $scope.ui.materials, (material) ->
        material.isSelect = false
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.setSelect = (material) ->
    material.isSelect = !material.isSelect
    if $scope.ui.radio is true
      _.each $scope.ui.materials, (material1) ->
        if material1.article_id isnt material.article_id then material1.isSelect = false

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    materials = _.where($scope.ui.materials, {isSelect: true})
    if materials.length < 1
       #  == true
      $scope.ui.saveState = false
      growl.error("請至少選擇一篇圖文或取消")
      return
    # if materials[0].articles.length > 1 && data.prompt
    #   SweetAlert.swal {
    #     title: "你確定嗎?"
    #     text: "圖文數量超出限制，微信僅回覆第1條。"
    #     type: "warning"
    #     showCancelButton: true
    #     confirmButtonClass: 'btn-danger'
    #     cancelButtonText: '取消'
    #     confirmButtonText: '確定'
    #   }, (isConfirm) ->
    #     if isConfirm
    #       $scope.ui.saveState = true

    #       $uibModalInstance.close materials
    # else
    $scope.ui.saveState = true

    $uibModalInstance.close materials

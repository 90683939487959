angular.module 'app'
.controller 'QaCtrl', ($rootScope, $scope, $http, $stateParams, $state, $location, config, Storage, SweetAlert, $timeout, dialogs, growl, FileUploader) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').interaction.smartService.is_use == false

  $scope.ui =
    permission: Storage.get('user_permission')
    isLoading: false
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    appmsgs: []
    rebuild: ''
    is_checked: ''
    search:
      text: ''
    status: ''
    last_rebuild_time: ''
    choseTesters: []
    sortt: ''
    firstKeywords: []
    secondKeywords: []
    activeFirstKeyword: ''
    activeSecondKeyword: ''
    firstKeyword: ''
    secondKeyword: ''

  findDatas = ->
    if $scope.ui.secondKeywords[$scope.ui.activeSecondKeyword]
      $scope.ui.secondKeyword = $scope.ui.secondKeywords[$scope.ui.activeSecondKeyword]
    else
      $scope.ui.secondKeyword = ''
    $scope.ui.secondKeyword = '' if $scope.ui.firstKeyword == ''
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/dsi/qa?access_token=" + Storage.get('access_token'),
      params:
        question: ''
        answer: ''
        first: $scope.ui.firstKeyword
        second: $scope.ui.secondKeyword
        q: $scope.ui.search.text
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        sort: $scope.ui.sortt
        status: $scope.ui.status
        is_default: false
    .then (res) ->
      _.each res.data.data, (data) ->
        data.updated_at = moment(data.updated_at).format('YYYY-MM-DD')
        data.status = '新增' if data.status == 'create'
        data.status = '已修改' if data.status == 'update'
        data.status = '已刪除' if data.status == 'delete'
        # data.first = '--' if data.first == 'first'
        # data.second = '--' if data.second == 'second'
        data.first = '--' if data.first == ''
        data.second = '--' if data.second == ''
      $scope.ui.is_checked = res.data.is_checked
      $scope.ui.rebuild = res.data.rebuild
      $scope.ui.appmsgs = res.data.data
      $scope.ui.totalItems = res.data.count
      $scope.ui.last_rebuild_time = moment(res.data.last_rebuild_time.prod).format('YYYY-MM-DD HH:mm') if res.data.last_rebuild_time.prod
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findTesters = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/dsi/qa-tester?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.choseTesters = res.data.is_add
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  #主關鍵字
  do fetchFirst = ->
    $http.get "#{config.api.baseUrl}/dsi/qa-keyword?first=", {}
    .then (res) ->
      $scope.ui.firstKeywords = res.data.keywords
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  #次關鍵字
  fetchSecond = ->
    $http.get "#{config.api.baseUrl}/dsi/qa-keyword?first=" + $scope.ui.firstKeyword, {}
    .then (res) ->
      $scope.ui.secondKeywords = res.data.keywords
      $scope.ui.secondKeyword = $scope.ui.secondKeywords[$scope.ui.activeSecondKeyword]
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 詳情
  $scope.detail = (appmsg) ->
    dlg = dialogs.create('apps/smartService/detail/detail', 'QaDetailCtrl', {appmsg: appmsg}, {size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
    ), ->

  #添加
  $scope.add = (appmsg) ->
    dlg = dialogs.create('apps/smartService/add/addQa', 'AddQaCtrl', {appmsg: ''}, {size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findDatas()
    ), ->

  #修改
  $scope.editDetail = (appmsg) ->
    dlg = dialogs.create('apps/smartService/add/addQa', 'AddQaCtrl', {appmsg: appmsg}, {size: 'lg'})
    dlg.result.then ((option) ->
      findDatas()
      return if option == 'Cancel'
    ), ->

  #刪除
  $scope.delAppmsg = (appmsg) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{appmsg.question}”?"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/dsi/qa/#{appmsg._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findDatas()
          growl.success '刪除成功'
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  # 重跑模型
  $scope.rebuildModel = ->
    return if $scope.ui.rebuild.prod == false && $scope.ui.rebuild.test == false
    dlg = dialogs.create('apps/smartService/rebuild/rebuildModel', 'RebuildModelCtrl', {qa: 'qa', last_rebuild_time: $scope.ui.last_rebuild_time, rebuild: $scope.ui.rebuild, choseTesters: $scope.ui.choseTesters, is_checked: $scope.ui.is_checked}, {backdrop: 'static', size: 'lg'})
    dlg.result.then ((option) ->
      findDatas()
      findTesters()
      return if option == 'Cancel'
    ), ->

  #關鍵字排序
  $scope.sortFirstKeyword = ->
    $scope.ui.totalItems = 0
    $scope.ui.currentPage = 1
    $scope.ui.appmsgs = []
    $scope.ui.sortt = JSON.stringify({first: -1, second: -1})
    findDatas()

  # 更新時間排序
  $scope.sortTime = ->
    $scope.ui.totalItems = 0
    $scope.ui.currentPage = 1
    $scope.ui.appmsgs = []
    $scope.ui.sortt = '-updated_at'
    findDatas()

  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.download = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/dsi/qa/export_excel?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        q: $scope.ui.search.text
        status: $scope.ui.status
      prepareCallback: prepare
      successCallback: success
      failCallback: error


  $scope.searchText = ->
    $scope.ui.totalItems = 0
    $scope.ui.currentPage = 1
    $scope.ui.appmsgs = []
    $scope.ui.sortt = ''
    findDatas()

  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.ui.totalItems = 0
      $scope.ui.currentPage = 1
      $scope.ui.appmsgs = []
      findDatas()

  $scope.statusNormal = ->
    $scope.ui.totalItems = 0
    $scope.ui.currentPage = 1
    $scope.ui.status = ''
    findDatas()

  $scope.statusUnusual = ->
    $scope.ui.totalItems = 0
    $scope.ui.currentPage = 1
    $scope.ui.status = 'no_normal'
    findDatas()


  findTesters()
  $scope.$watch 'ui.currentPage', findDatas

  $scope.$watch 'ui.activeFirstKeyword', (newValue, oldValue) ->
    if $scope.ui.firstKeywords[$scope.ui.activeFirstKeyword]
      $scope.ui.firstKeyword = $scope.ui.firstKeywords[$scope.ui.activeFirstKeyword]
    else
      $scope.ui.firstKeyword = ''
    fetchSecond()


angular.module 'app'
.controller 'EditStudentCtrl', ($scope, $http, $uibModalInstance, SweetAlert, config, growl, data) ->

  $scope.ui =
    show_parent_code: false
    student: angular.copy data.student
    classData: angular.copy data.classData
    schools: [
      key: "校本部中學、國際部中小學"
      val: "170"
    ,
      key: "校本部小學、幼稚園"
      val: "019"
    ,
      key: "國際部幼稚園 "
      val: "163"
    ]
    leave_type: 'all'
    is_leave: if data.type and data.type is 'leave' then true else false

  if $scope.ui.student.guardian
    $scope.ui.student.guardian.name = switch $scope.ui.student.guardian.identity
      when "father" then "父親"
      when "mother" then "母親"
      else "其他"

  if $scope.ui.student.parents?.length > 0
    findObj = _.findWhere $scope.ui.student.parents, level: "guardian"
    $scope.ui.show_parent_code = true if !findObj
  else
    $scope.ui.show_parent_code = true

  $scope.delGuardian = ->
    parents = _.reject $scope.ui.student.parents, (val) ->
      val.level is "guardian"

    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否解除監護人綁定？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $scope.ui.student.parents = parents
        $http.put "#{config.api.baseUrl}/cumpus/student/#{$scope.ui.student._id}", $scope.ui.student
        .then (res) ->
          $scope.ui.show_parent_code = !$scope.ui.show_parent_code
          growl.success '解綁成功！', ttl: 2000
          $uibModalInstance.close 'Save'

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $http.put "#{config.api.baseUrl}/cumpus/student/#{$scope.ui.student._id}", $scope.ui.student
    .then (res) ->
      growl.success '保存成功！', ttl: 2000
      $uibModalInstance.close 'Save'
    .catch (error) ->
      growl.error '保存失敗'

  $scope.leave = ->
    if $scope.ui.leave_type is 'all'
      growl.error '請選擇離校方式'
      return

    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否將該學生設置為離校狀態？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.post "#{config.api.baseUrl}/cumpus/student/change", {student_id: $scope.ui.student.student_id, change_type: $scope.ui.leave_type}
        .then (res) ->
          growl.success '保存成功'
          $uibModalInstance.close 'Save'
        .catch (error) ->
          growl.error '保存失敗'

angular.module 'app'
.controller 'StudentCtrl', ($scope, $http, $stateParams, $state, config, Storage, growl, dialogs, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').campus.schoolInformation.is_use == false

  $scope.ui =
    isLoading: false
    user: Storage.get('login_user')

    sort: 'created_at'
    student_class: ''
    student_grade: ''

    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0

    students: []
    classData: {}
    projectId: $stateParams.projectId
    schools: [
      key: "校本部中學、國際部中小學"
      val: "170"
    ,
      key: "校本部小學、幼稚園"
      val: "019"
    ,
      key: "國際部幼稚園 "
      val: "163"
    ]

  findData = ->
    $scope.ui.isLoading = true
    myparams =
      limit: $scope.ui.perPage
      offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
      group_id: $scope.ui.user.group_id
      status: '1'
      student_class: $scope.ui.student_class
      student_grade: $scope.ui.student_grade
      student_id: $scope.ui.student_id
      school_num: $scope.ui.school_num
      sort: $scope.ui.sort

    if $scope.ui.is_verification is 'true'
      myparams.is_verification = true
    else if $scope.ui.is_verification is 'false'
      myparams.is_verification = ''

    $http.get "#{config.api.baseUrl}/cumpus/student",
      params: myparams
    .then (res) ->
      _.each res.data.data, (val) ->
        val.parent = ""
        if val.parents
          _.each val.parents, (valParent, index) ->
            identity = switch valParent.identity
              when "father" then "父親"
              when "mother" then "母親"
              else "其他"
            identity = "/" + identity if index isnt 0
            valParent.name = if val.identity then "/#{identity}" else identity
      $scope.ui.students = res.data.data
      $scope.ui.totalItems = res.data.count
      $scope.ui.isLoading = false
    .catch (error) ->
      $scope.ui.isLoading = false
      growl.error '查詢錯誤'

  $scope.$watch 'ui.currentPage', findData

  $scope.setSort = (sortType) ->
    if $scope.ui.sort.indexOf(sortType) >= 0
      $scope.ui.sort = if $scope.ui.sort.indexOf('-') >= 0 then sortType else "-#{sortType}"
    else
      $scope.ui.sort = sortType
    findData()

  do findClass = ->
    $http.get "#{config.api.baseUrl}/cumpus/class",
      params:
        group_id: $scope.ui.user.group_id
    .then (res) ->
      $scope.ui.classData = setSort res.data
    .catch (error) ->

  setSort = (data) ->
    data.student_grade = data.student_grade.sort()
    res_class = []
    _.each data.student_class, (val) ->
      obj = {}
      obj.name = val
      obj.key = switch val
        when "甲" then 1
        when "乙" then 2
        when "丙" then 3
        when "丁" then 4
        when "戊" then 5
        when "己" then 6
        when "庚" then 7
        when "辛" then 8
        when "壬" then 9
        when "癸" then 10
      res_class.push obj
    data.student_class = _.sortBy res_class, "key"
    res_grade = []
    _.each data.student_grade, (val) ->
      obj = {}
      obj.name = val
      obj.key = switch val
        when "幼兒" then 1
        when "幼初" then 2
        when "幼高" then 3
        when "小一" then 4
        when "小二" then 5
        when "小三" then 6
        when "小四" then 7
        when "小五" then 8
        when "小六" then 9
        when "初一" then 10
        when "初二" then 11
        when "初三" then 12
        when "高一" then 13
        when "高二" then 14
        when "高三" then 15
      res_grade.push obj
    data.student_grade = _.sortBy res_grade, "key"
    data

  $scope.selSchool = ->
    $http.get "#{config.api.baseUrl}/cumpus/class",
      params:
        group_id: $scope.ui.user.group_id
        school_num: $scope.ui.school_num
    .then (res) ->
      $scope.ui.classData = setSort res.data
      $scope.ui.student_grade = ""
      $scope.ui.student_class = ""
    .catch (error) ->

  $scope.selGrade = ->
    $http.get "#{config.api.baseUrl}/cumpus/class",
      params:
        group_id: $scope.ui.user.group_id
        school_num: $scope.ui.school_num
        student_grade: $scope.ui.student_grade
    .then (res) ->
      $scope.ui.classData = setSort res.data
      $scope.ui.student_class = ""
    .catch (error) ->

  $scope.search = ->
    $scope.ui.currentPage = 1
    $scope.ui.totalItems = 0
    findData()

  $scope.importStudent = ->
    dlg = dialogs.create('apps/campus/school-information/modal/import-student/import-student', 'ImportStudentCtrl', {}, {size: 'lg'})
    dlg.result.then ((option) ->
      $scope.ui.currentPage = 1
      $scope.ui.totalItems = 0
      findClass()
      findData()
    ), ->

  $scope.del = (student) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{student.student_id}”學生嗎？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/cumpus/student/#{student._id}", {}
        .then (res) ->
          findData()
          growl.success '刪除成功'
        .catch (error) ->
          growl.error '刪除失敗'

  $scope.delAll = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除所有學生信息嗎？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/cumpus/student?group_id=#{$scope.ui.user.group_id}&status=1", {}
        .then (res) ->
          findData()
          growl.success '刪除成功'
        .catch (error) ->
          growl.error '刪除失敗'

  $scope.edit = (student) ->
    dlg = dialogs.create('apps/campus/school-information/modal/edit-student/edit-student', 'EditStudentCtrl', {student: student, classData: $scope.ui.classData}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findData()
    ), ->

  $scope.leave = (student) ->
    dlg = dialogs.create('apps/campus/school-information/modal/edit-student/edit-student', 'EditStudentCtrl', {student: student, classData: $scope.ui.classData, type: 'leave'}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findData()
    ), ->

  $scope.downloadCsv = ->
    myparams =
      type: 'student'
      group_id: $scope.ui.user.group_id
      status: '1'
      student_class: $scope.ui.student_class
      student_grade: $scope.ui.student_grade
      student_id: $scope.ui.student_id
      sort: 'student_class'

    if $scope.ui.is_verification is 'true'
      myparams.is_verification = true
    else if $scope.ui.is_verification is 'false'
      myparams.is_verification = ''

    $.fileDownload "#{config.api.baseUrl}/cumpus/export/csv",
      httpMethod: "POST"
      data: myparams

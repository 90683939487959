angular.module 'app'
.controller 'SelectPeopleCtrl', ($rootScope, $timeout, $scope, $http, $stateParams, $state, config, SocketIO, Storage, dialogs, growl, SweetAlert, $uibModalInstance, data) ->

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    wechat: {}
    isLoading: false
    isGroupLoading: false
    showQrcodeList: false
    currentPage: 1
    perPage: 8
    maxSize: 8
    totalItems: 0
    isSelectAll: false
    choseFans: []
    choseFansData: []
    fans: []
    qrcodeFans: []
    showQrcode: false
    qrcodeUrl: ""
    ungroups: [
        name: '全部'
        tag_id: 'all'
        tag_count: 0
    ]
    activeGroup:
      name: '全部'
      tag_id: 'all'
    groups: []
    search:
      nickname: null
    tipText: data.text

  if data.openid
    $scope.ui.choseFans = data.openid
    if !_.isEmpty data.openid
      $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/infors/all?access_token=" + Storage.get('access_token'),
        params:
          openid: data.openid
      .then (res) ->
        $scope.ui.choseFansData = res.data.data


  # 修改備註
  $scope.fanRemarkPopover =
    title: "備註名稱"
    data: null
    remark: ''
    templateUrl: 'fanRemarkPopoverTemplate.html'
    save: ->
      dialogs.wait '請稍後', '正在處理...'
      $scope.ui.editGroupDisabled = true
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/remark?access_token=" + Storage.get('access_token'),
        openid: $scope.fanRemarkPopover.data.openid
        remark: $scope.fanRemarkPopover.remark
      .then (res) ->
        _.each $scope.ui.qrcodeFans, (fan) ->
          if $scope.fanRemarkPopover.data._id is fan._id
            fan.remark = $scope.fanRemarkPopover.remark
        growl.success "保存成功！"
        $scope.ui.editGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.editGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'

  $scope.setFanRemarkPopover = (fan) ->
    $scope.fanRemarkPopover.data = angular.copy fan
    if $scope.fanRemarkPopover.data.remark.length > 0
      $scope.fanRemarkPopover.remark = $scope.fanRemarkPopover.data.remark
    else
      $scope.fanRemarkPopover.remark = $scope.fanRemarkPopover.data.nickname

  # 查詢粉絲分組
  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'), {
      params:
        limit: 99
    }
    .then (res) ->
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findDatas = ->
    $scope.ui.isLoading = true
    $scope.ui.isSelectAll = false
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/infors/#{$scope.ui.activeGroup.tag_id}?access_token=" + Storage.get('access_token'),
      params:
        nickname: $scope.ui.search.nickname
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        sort: "-subscribe_time"
    .then (res) ->
      $scope.ui.ungroups[0].tag_count = res.data.count if $scope.ui.ungroups[0].tag_count < 1

      $scope.ui.totalItems = res.data.count
      $scope.ui.fans = res.data.data

      _.each $scope.ui.fans, (fan) ->
        fan.group_name = $scope.ui.activeGroup.name
        fan.isChecked = false
        fan.isChecked = true if _.contains $scope.ui.choseFans, fan.openid
        fan.subscribe_time = moment(fan.subscribe_time).format('YYYY-MM-DD')
        fan.fansLanguage = switch fan.language
          when 'zh_CN' then '簡體中文'
          when 'zh_TW' then '繁体中文TW'
          when 'zh_HK' then '繁体中文HK'
          when 'en' then '英文'
          when 'id' then '印尼'
          when 'ms' then '馬來'
          when 'es' then '西班牙'
          when 'ko' then '韓國'
          when 'it' then '意大利'
          when 'ja' then '日本'
          when 'pl' then '波蘭'
          when 'pt' then '葡萄牙'
          when 'ru' then '俄國'
          when 'th' then '泰文'
          when 'vi' then '越南'
          when 'ar' then '阿拉伯語'
          when 'hi' then '北印度'
          when 'he' then '希伯來'
          when 'tr' then '土耳其'
          when 'de' then '德語'
          when 'fr' then '法語'
          else '其他'
        fan.tags = []
        _.each fan.tagid_list, (id) ->
          _.each $scope.ui.groups, (group) ->
            if String(id) is group.tag_id
              fan.tags.push group.name
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  ### 获取wechat ###
  do fetchWechat = ->
    $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        original_id: $stateParams.original_id
    .then (res) ->
      if res.data.count > 0
        $scope.ui.wechat = res.data.data[0]
        if $scope.ui.wechat.type != '0'
          $scope.ui.showQrcodeList = true
          findQrcode()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoad = false

  findQrcode = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcode?access_token=" + Storage.get('access_token'),
      params:
        type: 'QR_STR_SCENE'
        expire_seconds: 3600
        scene: "preview_wechat_#{$scope.ui.user.id}"
    .then (res) ->
      $scope.ui.qrcodeUrl = res.data.qrcode_url if res.data
      SocketIO.emit 'clientID', "preview_wechat_#{$scope.ui.user.id}", "start"
      SocketIO.on "preview_wechat_#{$scope.ui.user.id}", (data) ->
        if data.openid
          $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/infors/all?access_token=" + Storage.get('access_token'),
            params:
              openid: data.openid
          .then (res) ->
            if res.data.count > 0
              fan = _.findWhere $scope.ui.qrcodeFans, data
              res.data.data[0].isChecked = true
              $scope.ui.qrcodeFans.push res.data.data[0] if !fan
              if !_.contains $scope.ui.choseFans, res.data.data[0].openid
                $scope.ui.choseFans.push res.data.data[0].openid
                $scope.ui.choseFansData.push res.data.data[0]
              # $scope.ui.tipText = "已選粉絲 #{$scope.ui.choseFans.length} 人。"

  $scope.search = ->
    $scope.ui.totalItems = 0
    $scope.ui.fans = []
    $scope.ui.currentPage = 1
    findDatas()

  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.ui.totalItems = 0
      $scope.ui.fans = []
      $scope.ui.currentPage = 1
      findDatas()

  $scope.selectAll = ->
    if $scope.ui.isSelectAll
      _.each $scope.ui.fans, (fan) ->
        if !_.contains $scope.ui.choseFans, fan.openid
          $scope.ui.choseFans.push fan.openid
          $scope.ui.choseFansData.push fan
        fan.isChecked = $scope.ui.isSelectAll
      # $scope.ui.tipText = "已選粉絲 #{$scope.ui.choseFans.length} 人。"
    else
      _.each $scope.ui.fans, (fan) ->
        $scope.ui.choseFans = _.filter $scope.ui.choseFans, (val) ->
          val if val isnt fan.openid
        $scope.ui.choseFansData = _.filter $scope.ui.choseFansData, (val) ->
          val if val.openid isnt fan.openid
        fan.isChecked = $scope.ui.isSelectAll
      # $scope.ui.tipText = data.text

  $scope.deleteSelect = ->
    $scope.ui.choseFans = []
    $scope.ui.choseFansData = []
    # $scope.ui.tipText = ''
    $scope.ui.isSelectAll = false
    _.each $scope.ui.fans, (fan) ->
      fan.isChecked = false

  # 選中左邊標簽
  $scope.setActiveGroup = (group) ->
    if group.tag_id is 'preview'
      $scope.ui.activeGroup = { tag_id: 'preview' }
      _.each $scope.ui.qrcodeFans, (fan) ->
        fan.isChecked = false
        fan.isChecked = true if _.contains $scope.ui.choseFans, fan.openid
      $scope.ui.showQrcode = true
    else
      $scope.ui.showQrcode = false
      $scope.ui.activeGroup = group
      $scope.ui.totalItems = 0
      $scope.ui.fans = []
      $scope.ui.currentPage = 1
      findDatas()

  $scope.changeSelectFan = (fan) ->
    if fan.isChecked
      if data.single == true
        if $scope.ui.choseFans.length == 0
          if !_.contains $scope.ui.choseFans, fan.openid
            $scope.ui.choseFans.push fan.openid
            $scope.ui.choseFansData.push fan
        else
          fan.isChecked = false
          growl.error "只可選擇一名運營者微信號"
      else
        if !_.contains $scope.ui.choseFans, fan.openid
          $scope.ui.choseFans.push fan.openid
          $scope.ui.choseFansData.push fan
    else
      $scope.ui.choseFans = _.filter $scope.ui.choseFans, (val) ->
        val if val isnt fan.openid
      $scope.ui.choseFansData = _.filter $scope.ui.choseFansData, (val) ->
        val if val.openid isnt fan.openid
    # if $scope.ui.choseFans.length is 0
    #   $scope.ui.tipText = data.text
    # else
    #   $scope.ui.tipText = "已選粉絲 #{$scope.ui.choseFans.length} 人。"

  $scope.deleteSelectFan = (fan) ->
    _.each $scope.ui.fans, (_fan) ->
      if _fan.openid == fan.openid
        _fan.isChecked = false
    _.each $scope.ui.qrcodeFans, (_fan) ->
      if _fan.openid == fan.openid
        _fan.isChecked = false
    $scope.ui.choseFans = _.filter $scope.ui.choseFans, (val) ->
      val if val isnt fan.openid
    $scope.ui.choseFansData = _.filter $scope.ui.choseFansData, (val) ->
      val if val.openid isnt fan.openid

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    return growl.error "請選擇粉絲" if $scope.ui.choseFans.length < 1
    $uibModalInstance.close $scope.ui.choseFans

  findGroups()
  $scope.$watch 'ui.currentPage', findDatas

angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    # 素材
    .state 'app.material',
      url: '/material'
      templateUrl: 'apps/material/layout/layout'
      controller: 'MaterialLayoutCtrl'

    # 素材－图片
    .state 'app.material.material-image',
      url: '/material-image'
      templateUrl: 'apps/material/image/image'
      controller: 'MaterialImageCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])
    # 素材－音频
    .state 'app.material.material-audio',
      url: '/material-audio'
      templateUrl: 'apps/material/audio/audio'
      controller: 'MaterialAudioCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])
    # 素材－视频
    .state 'app.material.material-video',
      url: '/material-video'
      templateUrl: 'apps/material/video/video'
      controller: 'MaterialVideoCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 素材－图文
    .state 'app.material.material-graphic',
      url: '/material-graphic'
      templateUrl: 'apps/material/draft/graphic'
      controller: 'DraftGraphicCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])

    # 素材－图文模板
    .state 'app.material.material-template',
      url: '/material-template'
      templateUrl: 'apps/material/template/template'
      controller: 'MaterialTemplateCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])

    # 素材－官方库管理
    .state 'app.material.material-manage',
      url: '/material-manage'
      templateUrl: 'apps/material/manage/manage'
      controller: 'MaterialManageCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])
    
    # 素材－图文
    .state 'app.history-graphic',
      url: '/history-graphic'
      templateUrl: 'apps/material/graphic/graphic'
      controller: 'MaterialGraphicCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])

angular.module 'app'
.controller 'NewTaskCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').issue.information.informationNew.is_use == false

  $scope.ui =
    wechatidList: []
    isSelectAll: false
    radioShow: true
    select_is_multiple: false
    wechats: Storage.get('Wechats')
    isDisabled: false

    user: Storage.get('login_user')
    resourcesUrl: config.resources.baseUrl
    menuActive: 0


    msgObjType: '0'
    isShowTimeSelect: false

    send_ignore_reprint: '0'

    tag_id: 'all'
    fansGroups: []

    fansArr: []

    graphic: []
    image: []
    audio: []
    video: []
    text: ''

    date: moment().format('YYYY-MM-DD HH:mm:ss')
    opts:
      autoApply: true
      parentEl: 'div#view-fans-message'
      minDate: moment().format('YYYY-MM-DD HH:mm:ss')
      opens: 'center'
      timePicker: true
      timePicker24Hour: true
      timePickerSeconds: true
      singleDatePicker: true
      locale:
        format: 'YYYY-MM-DD HH:mm:ss'
        applyLabel: '應用'
        cancelLabel: '取消'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']

  # 圖表
  $scope.chartConfig =
    title:
      text: ''
    tooltip:
      trigger: 'axis'
    legend:
      data: ['粉絲活躍時間趨勢']
      bottom: 20
    grid:
      containLabel: true
    toolbox:
      show: true
      feature:
        restore:
          show: true
        saveAsImage:
          show: true
    xAxis: [{
      type: 'category'
      boundaryGap: false
      splitLine:
        show: false
      data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
    }]
    yAxis: [
      type: 'value'
      axisLabel:
        formatter: '{value}'
    ]
    series: [{
      name: '粉絲活躍時間趨勢'
      type: 'line'
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }]

  do fetchData = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/userreadhour?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      $scope.chartConfig.series[0].data = res.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  #是否選擇多賬號發送
  $scope.selectMutiple = ->
    if $scope.ui.select_is_multiple == true
      $scope.ui.radioShow = false
    else
      $scope.ui.radioShow = true


  #全選
  $scope.selectAll = ->
    if $scope.ui.isSelectAll == true
      _.each $scope.ui.wechats, (wechat) ->
        if !wechat.isChecked
          wechat.isChecked = true
          $scope.ui.wechatidList.push wechat.original_id
    else
      _.each $scope.ui.wechats, (wechat) ->
        if wechat.isChecked == true
          wechat.isChecked = false
          $scope.ui.wechatidList = _.filter $scope.ui.wechatidList, (wechat_originalid) ->
            wechat_originalid if wechat.original_id isnt wechat_originalid

  # 复选
  $scope.selectSingle = (data) ->
    if data.isChecked == true
      $scope.ui.wechatidList.push data.original_id
    else
      $scope.ui.wechatidList = _.filter $scope.ui.wechatidList, (wechat_originalid) ->
        wechat_originalid if data.original_id isnt wechat_originalid

  # 切换焦点
  $scope.switchMenu = (_index) ->
    return growl.error('音頻發佈暫未開放') if _index == 2
    # return growl.error('視頻發佈暫未開放') if _index == 3
    $scope.ui.menuActive = _index

  # 选择图文
  $scope.selectGraphic = ->
    dialogs.create('apps/material/graphic/modal/modal', 'MaterialModalCtrl', {radio: true}, {size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.graphic = option
    ), ->
  
  # 草稿箱選擇圖文
  $scope.selectDraft = ->
    dialogs.create('apps/material/draft/modal/modal', 'MaterialDraftModalCtrl', {radio: true, prompt: $scope.ui.prompt}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.graphic = option
    ), ->

  # 删除图文
  $scope.delGraphic = ->
    $scope.ui.graphic = []

  # 选择图片
  $scope.selectImage = ->
    dialogs.create('apps/material/image/choose/choose', 'ImageChooseCtrl', {radio: true}, {size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.image = option
    ), ->

  # 删除图片
  $scope.delImage = ->
    $scope.ui.image = []

  # 选择音频
  $scope.selectAudio = ->

  # 删除音频
  $scope.delAudio = ->
    $scope.ui.image = []

  # 选择视频
  $scope.selectVideo = ->
    dlg = dialogs.create('apps/material/video/choose/choose', 'VideoChooseCtrl', {type: 'information', radio: true}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.video = option
    ), ->

  # 删除视频
  $scope.delVideo = ->
    $scope.ui.video = []

  # 查詢粉絲分組
  findGroups = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'),{
      params:
        limit: 99
    }
    .then (res) ->
      $scope.ui.fansGroups = res.data.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 打开选择粉丝列表
  findFans = ->
    dlg = dialogs.create('apps/material/graphic/select/select', 'SelectPeopleCtrl', {text: '選擇人數必須小於等於 10000 人，大於等於 2 人', openid: $scope.ui.fansArr}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.fansArr = option
    ), ->

  # 发布提交
  $scope.informationSubmit = ->
    if $scope.ui.date is null or !moment($scope.ui.date).isValid()
      $scope.ui.date = moment().format('YYYY-MM-DD HH:mm:ss')
      return growl.error('请输入正确的时间')

    return growl.error('請選擇發佈內容') if $scope.ui.menuActive == 0 && $scope.ui.graphic.length < 1
    return growl.error('請選擇發佈內容') if $scope.ui.menuActive == 1 && $scope.ui.image.length < 1
    return growl.error('音頻發佈暫未開放') if $scope.ui.menuActive == 2
    # return growl.error('視頻發佈暫未開放') if $scope.ui.menuActive == 3
    return growl.error('請選擇發佈內容') if $scope.ui.menuActive == 4 && $scope.ui.text == ''
    return growl.error('自定義粉絲人數必須小於等於 10000 人，大於等於 2 人') if $scope.ui.msgObjType == '1' && ($scope.ui.fansArr.length < 2 || $scope.ui.fansArr.length > 10000)
    return growl.error('請選擇公眾號') if $scope.ui.wechatidList.length == 0 && $scope.ui.select_is_multiple == true
    $scope.ui.isDisabled = true

    _obj = {}

    _obj.type = 'news' if $scope.ui.menuActive == 0
    _obj.type = 'image' if $scope.ui.menuActive == 1
    _obj.type = 'voice' if $scope.ui.menuActive == 2
    _obj.type = 'video' if $scope.ui.menuActive == 3
    _obj.type = 'text' if $scope.ui.menuActive == 4

    _obj.send_type = 'immediately'
    _obj.send_type = 'timing' if $scope.ui.isShowTimeSelect == true

    _obj.send_time = ''
    _obj.send_time = $scope.ui.date if $scope.ui.isShowTimeSelect == true

    _obj.content = $scope.ui.graphic[0]._id if $scope.ui.menuActive == 0
    _obj.content = $scope.ui.image[0]._id if $scope.ui.menuActive == 1
    # _obj.content = $scope.ui.audio[0]._id if $scope.ui.menuActive == 2
    _obj.content = $scope.ui.video[0]._id if $scope.ui.menuActive == 3
    _obj.content = $scope.ui.text if $scope.ui.menuActive == 4

    _obj.is_to_all = false
    _obj.is_to_all = true if $scope.ui.msgObjType == '0' && $scope.ui.tag_id == 'all'

    _obj.tag_id = $scope.ui.tag_id if $scope.ui.msgObjType == '0'
    _obj.touser = $scope.ui.fansArr if $scope.ui.msgObjType == '1'

    _obj.created_by = $scope.ui.user.id

    _obj.original_ids = $scope.ui.wechatidList
    _obj.is_multiple = true if $scope.ui.select_is_multiple == true

    _obj.send_ignore_reprint = 0 if $scope.ui.send_ignore_reprint == '0'
    _obj.send_ignore_reprint = 1 if $scope.ui.send_ignore_reprint == '1'

    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/send?access_token=" + Storage.get('access_token'), _obj
    .then (res) ->
      growl.success('發佈申請提交成功')
      $scope.ui.msgObjType = '0'
      $scope.ui.tag_id = 'all'
      $scope.ui.send_ignore_reprint = '0'
      $scope.ui.select_is_multiple = false
      $scope.ui.graphic =  []
      $scope.ui.image =  []
      $scope.ui.audio =  []
      $scope.ui.video =  []
      $scope.ui.text =  ''
      $scope.ui.isDisabled = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isDisabled = false

  $scope.$watch 'ui.msgObjType', (newValue) ->
    if newValue == '0'
      findGroups()

  $scope.chooseFans = ->
    findFans()

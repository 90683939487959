angular.module 'app'
.controller 'MaterialTemplateManageCtrl', ($uibModalInstance, $scope, $http, $stateParams, $state, $sce, config, Storage, dialogs, growl, data, SweetAlert) ->
  $scope.ui =
    user: Storage.get('login_user')
    isDisable: false
    content: data.content
    mode: data.mode
    switchKey: 1
    activeClassify: ''
    classifyData: []
    name: ''


    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0

    radioModel: ''
    thisTableData: []

  # 获取分类
  do getClassifyData = ->
    $http.get "#{config.api.baseUrl}/ueditor/group?access_token=" + Storage.get('access_token'),
      params:
        mode: 'edit'
        user_group_id: $scope.ui.user.group_id
    .then (res) ->
      $scope.ui.classifyData = res.data.data
      $scope.ui.radioModel = $scope.ui.classifyData[0]._id if $scope.ui.classifyData.length > 0
      $scope.ui.activeClassify = $scope.ui.classifyData[0]._id if $scope.ui.classifyData.length > 0 && $scope.ui.mode == 'primary'
      $scope.$watch 'ui.currentPage', getTableData
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  # 保存
  $scope.save = ->
    return growl.error '編輯器HTML內容不可為空' if $scope.ui.content == ''
    return growl.error '請選擇其所屬分類' if $scope.ui.activeClassify == ''
    return growl.error '新分類名稱不可為空' if $scope.ui.activeClassify == 'add' && $scope.ui.name == ''


    # $scope.ui.content = $scope.ui.content.replace(/\"http\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\(http\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn')

    # 處理 svg 中的width
    _svg = $scope.ui.content.match(/<svg(S*?)[^>]*>/g)
    if _svg
      _.each _svg, (data) ->
        _style = data.match(/style=[\'\"]?([^\'\"]*)[\'\"]?/i)[0]
        _styleNew = _style.replace('width:0;', 'width:0 !important;')
        _dataNew = data.replace(_style, _styleNew)
        $scope.ui.content = $scope.ui.content.replace(data, _dataNew)
    $scope.ui.isDisable = true
    _params =
      content: $scope.ui.content
    _params.type = 'new' if $scope.ui.activeClassify == 'add'
    _params.name = $scope.ui.name if $scope.ui.activeClassify == 'add'
    _params.type = 'old' if $scope.ui.activeClassify != 'add'
    _params.group_id = $scope.ui.activeClassify if $scope.ui.activeClassify != 'add'

    $http.post "#{config.api.baseUrl}/ueditor/template?access_token=" + Storage.get('access_token'), _params
    .then (res) ->
      growl.success '保存成功'
      $uibModalInstance.close 'Save'
      $scope.ui.isDisable = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isDisable = false

  # 选择分类重新查询表格
  $scope.changeType = ->
    $scope.ui.thisTableData = []
    $scope.ui.currentPage = 1
    getTableData()

  # 查询表格
  getTableData = ->
    $http.get "#{config.api.baseUrl}/ueditor/template?access_token=" + Storage.get('access_token'),
      params:
        group_id: $scope.ui.radioModel
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      $scope.ui.thisTableData = res.data.data
      $scope.ui.totalItems = res.data.count
      _.each $scope.ui.thisTableData, (data) ->
        data.tip = $sce.trustAsHtml data.content
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 删除分类
  $scope.deleteClassify = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "刪除分類會將其分類下所有模板一起刪除，是否確定刪除該分類？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        return if $scope.ui.radioModel == ''

        $http.delete "#{config.api.baseUrl}/ueditor/group/#{$scope.ui.radioModel}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          getClassifyData()
          growl.success "刪除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  # 删除模板
  $scope.deleteData = (data) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除該模板？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/ueditor/template/#{data._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          getTableData()
          growl.success "刪除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

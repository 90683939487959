angular.module 'app'
.controller 'RemoteVideoChooseCtrl', ($scope, $http, $state, $stateParams, $uibModalInstance, config, Storage, FileUploader, growl, data) ->
  $scope.ui =
    waterMark: false
    detail: data.detail
    radio: data.radio
    type: data.type
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    materials: []
    saveState: false

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
    $scope.$watch 'ui.currentPage', findDatas
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  findDatas = ->
    $scope.ui.isLoading = true

    _params =
      type: 'list'
      materialType: "video"
      original_id: $stateParams.original_id
      count: $scope.ui.perPage
      offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage

    $http.post "#{config.api.baseUrl}/material/get-materials?access_token=" + Storage.get('access_token'), _params
    .then (res) ->
      $scope.ui.totalItems = res.data.total_count
      $scope.ui.materials = res.data.item
      _.each $scope.ui.materials, (data) ->
        data.file = {}
        data.file.thumb = "#{config.api.baseUrl}/imgserve?_url=" + encodeURIComponent(data.cover_url) if data.cover_url != '' && data.cover_url.indexOf('https://') == -1
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.setSelect = (image) ->
    image.isSelect = !image.isSelect
    if $scope.ui.radio is true
      _.each $scope.ui.materials, (material) ->
        if material.media_id isnt image.media_id then material.isSelect = false

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $scope.ui.saveState = true

    materials = _.where($scope.ui.materials, {isSelect: true})

    if materials.length < 1
      $scope.ui.saveState = false
      growl.error("請至少選擇一個視頻")
      return

    $uibModalInstance.close materials

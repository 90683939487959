angular.module 'app'
.controller 'HomeCtrl', ($scope, $http, $timeout, $state, config, Storage, dialogs, growl, SocketIO, SweetAlert, $location) ->
  
  $scope.ui =
    copyright: 'ers'
    hasNull: false
    loading: false
    email: ''
    password: ''
    isAutoSignIn: false
    eadminDisplay: config.eadmin.display
    ipError: false

  if $location.$$absUrl.indexOf('campusmd.powerwingdc.com/') > -1
  # if $location.$$absUrl.indexOf('microdata.ersinfotech.com/') < 0
    $scope.ui.copyright = 'campus'

  $scope.thisYear = moment().format('YYYY')

  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.doLogin()

  do isAutoSignIn = ->
    if localStorage.isAutoSignIn and localStorage.isAutoSignIn isnt "false"
      $scope.ui.loading = true
      _baseUrl = ''
      if $scope.ui.eadminDisplay == true
        _baseUrl = config.eadmin.newBaseUrl
      else
        _baseUrl = config.eadmin.baseUrl
      $http.post "#{_baseUrl}/oauth/signin",
        email: localStorage.email
        password: localStorage.password
        client_id: config.eadmin.clientId
      .then (res) ->
        if res.data.access_token
          Storage.set('access_token', res.data.access_token)
          socketClick()
        else
          $scope.ui.error = true
          $scope.ui.loading = false
      .catch (err) ->
        if err.data.error.indexOf('authentication invalid') > -1
          $scope.ui.hasError = true
        else if err.data.error.indexOf('user invalid') > -1
          $scope.ui.userError = true
        else
          $scope.ui.error = true
        $scope.ui.loading = false

  $scope.doLogin = ->
    $scope.ui.loading = true
    if $scope.ui.email.length is 0 or $scope.ui.password.length is 0
      $scope.ui.hasNull = true
      $timeout((->
        $scope.ui.hasNull = false
      ), 2000)
      $scope.ui.loading = false
      return

    localStorage.email = $scope.ui.email
    localStorage.password = $scope.ui.password

    localStorage.isAutoSignIn = $scope.ui.isAutoSignIn if $scope.ui.isAutoSignIn

    _baseUrl = ''
    if $scope.ui.eadminDisplay == true
      _baseUrl = config.eadmin.newBaseUrl
    else
      _baseUrl = config.eadmin.baseUrl
    $http.post "#{_baseUrl}/oauth/signin",
      email: $scope.ui.email
      password: $scope.ui.password
      client_id: config.eadmin.clientId
    .then (res) ->
      if res.data.access_token
        Storage.set('access_token', res.data.access_token)
        socketClick()
      else
        $scope.ui.error = true
        $scope.ui.loading = false
    .catch (err) ->
      if err.data.error.indexOf('authentication invalid') > -1
        $scope.ui.hasError = true
      else if err.data.error.indexOf('user invalid') > -1
        $scope.ui.userError = true
      else
        $scope.ui.error = true
      $scope.ui.loading = false

  $scope.retrieve = ->
    dlg = dialogs.create('apps/user/retrieve-password/retrieve-password', 'RetrievePasswordCtrl', {}, {size: 'md'})
    dlg.result.then ((option) ->
    ), ->

  socketClick = ->
    _baseUrl = ''
    _resData = {}
    isLogin = true
    if $scope.ui.eadminDisplay == true
      _baseUrl = config.eadmin.newBaseUrl
    else
      _baseUrl = config.eadmin.baseUrl
    $http.get "#{_baseUrl}/account/me",
      params:
        access_token: Storage.get('access_token')
    .then (res) ->
      _resData = res.data
      if(_resData.permission && _resData.permission.whiteList)
        $http.get "#{_baseUrl}/ip",
          params: {}
        .then (resIP) ->
          if !_.contains(_resData.permission.whiteList, resIP.data)
            localStorage.email = ""
            localStorage.password = ""
            localStorage.isAutoSignIn = false
            Storage.remove('access_token')
            Storage.remove('login_user')
            Storage.remove('user_permission')
            Storage.remove('Wechats')
            Storage.remove('defalult_wechat')
            isLogin = false
            $scope.ui.ipError = true
            $scope.ui.loading = false
            growl.error '您的帳號不在IP白名單內，無法登陸。請聯繫管理員以獲取幫助。'
    .then ->
      if isLogin
        Storage.set('login_user', _resData)
        Storage.set('user_permission', _resData.permission)

        _userId = Storage.get('login_user').id

        _loginOnly = Storage.get('user_permission').loginOnly

        return login() if _loginOnly == false

        SocketIO.emit 'clientID', 'WeChat-Login-' + _userId, (n) ->
          return login() if n <= 0

          SweetAlert.swal {
            title: "你確定嗎?"
            text: "您的帳號已在別處登錄，是否強制登錄？"
            type: "warning"
            showCancelButton: true
            confirmButtonClass: 'btn-danger'
            cancelButtonText: '取消'
            confirmButtonText: '確定'
          }, (isConfirm) ->
            return login() if isConfirm

            Storage.remove('access_token')
            Storage.remove('login_user')
            Storage.remove('user_permission')
            $scope.ui.loading = false
    .catch (error) ->
      Storage.remove('access_token')
      $state.go('home')

  $scope.$watch 'ui.email', (newValue, oldValue) ->
    if newValue != oldValue
      $scope.ui.hasError = false
      $scope.ui.userError = false

  $scope.$watch 'ui.password', (newValue, oldValue) ->
    if newValue != oldValue
      $scope.ui.hasError = false
      $scope.ui.userError = false

  login = ->
    SocketIO.emit 'clientID', 'WeChat-Login-' + Storage.get('login_user').id, 'logout' if Storage.get('user_permission').loginOnly != false
    growl.info '登錄成功，正在跳轉...'
    $scope.ui.loading = false
    $state.go 'app.wechat'
angular.module 'app'
.controller 'AddCoverTemplateCtrl', ($rootScope, $scope, $http, $stateParams,$uibModalInstance, $state, config, Storage, dialogs, data, growl, SweetAlert) ->

  $scope.ui =
    isLoading: false
    updateDisabled: false
    user: Storage.get('login_user')
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    totalItemsOne: 0
    currentPageOne: 1
    perPageOne: 10
    maxSizeOne: 6
    sentLists: []
    selectcount: 3 - data.cover_lists.length
    sentListsSelectedIds: []
    status: ''
    menuActive: 2
    title: ''
    chosenLists: data.cover_lists

  findDatas = ->
    _type = ''
    _type = 'sent' if $scope.ui.menuActive == 0
    _type = 'material' if $scope.ui.menuActive == 1
    _type = 'draft' if $scope.ui.menuActive == 2
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/homepage/getSentList?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
        type: _type
        search: $scope.ui.title
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      _.each res.data.data, (val) ->
        val.isChecked = false
        val.created_at = moment(val.created_at).format(if $scope.ui.menuActive == 2 then "YYYY年MM月DD日 HH:mm:ss" else "YYYY年MM月DD日")
        _.each $scope.ui.chosenLists, (list) ->
          val.isChecked = true if list.sent_id == val._id
      $scope.ui.sentLists = res.data.data
      $scope.ui.totalItems = res.data.count
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.currentPage', findDatas

  # 切换焦点
  $scope.switchMenu = (_index) ->
    $scope.ui.menuActive = _index
    $scope.ui.title = ''
    $scope.ui.totalItems = 0
    $scope.ui.currentPage = 1
    findDatas()

  # 搜索
  $scope.search = ->
    $scope.ui.totalItems = 0
    $scope.ui.sentLists = []
    $scope.ui.currentPage = 1
    findDatas()

  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.ui.totalItems = 0
      $scope.ui.sentLists = []
      $scope.ui.currentPage = 1
      findDatas()

  # 复选
  $scope.selectSingle = (data) ->
    data.sent_id = data._id
    if $scope.ui.selectcount <= 0 and data.isChecked
      data.isChecked = false
      growl.error('最多只能選擇3篇文章！')
      return
    data.isChecked == !data.isChecked

    if data.isChecked
      $scope.ui.selectcount--
      _index = _.findIndex $scope.ui.chosenLists, (list) ->
        return list.sent_id == data._id
      $scope.ui.chosenLists.push data if _index == -1
    else
      $scope.ui.selectcount++
      $scope.ui.chosenLists = _.filter $scope.ui.chosenLists, (list) ->
        return list.sent_id != data._id

  # 保存
  $scope.save = ->
    _result = []
    _listCover_id = []
    _.each $scope.ui.chosenLists, (val) ->
      _result.push {
        sent_id: val.sent_id
        url: val.url
        title: val.title
        thumb_url: val.thumb_url
        digest: val.digest
        created_at: val.created_at
      }
      _listCover_id.push {id: val.sent_id, url: val.url}
    $uibModalInstance.close {
      coverList: _result,
      coverListIds: _listCover_id
    }

  do findStatus = ->
    $http.get "#{config.api.baseUrl}/homepage/checkUpdateStatus?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.status = res.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 更新最近七天
  $scope.updateSevenData = (type) ->
    $scope.ui.isLoading = true
    $scope.ui.updateDisabled = true
    $http.post "#{config.api.baseUrl}/homepage/updateSentList?access_token=" + Storage.get('access_token'),
      original_id: $stateParams.original_id
      type: type
    .then (res) ->
      if res.status == 200
        growl.success "更新成功"
        findDatas()
      else
        growl.error "網絡連接失敗，請稍後重試"
    .finally ->
      $scope.ui.isLoading = false
      $scope.ui.updateDisabled = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 更新全部
  $scope.updateList = ->
    $scope.ui.isLoading = true
    $http.post "#{config.api.baseUrl}/homepage/updateSentList?access_token=" + Storage.get('access_token'),
      original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.status = "off"
      if res.status == 200
        growl.success "更新中，由於素材數量較多，需等待幾分鐘，請稍後重新進入查看"
      else
        growl.error "網絡連接失敗，請稍後重試"
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    _cover_lists = []
    _cover_lists = _.filter data.cover_lists, (list) ->
      return !list._id
    $uibModalInstance.close {
      coverList: _cover_lists
      coverListIds: data.coverLst_ids
    }


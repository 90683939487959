angular.module 'app'
.controller 'ImageChooseCtrl', ($scope, $http, $state, $stateParams, $uibModalInstance, config, Storage, FileUploader, growl, data) ->
  $scope.ui =
    waterMark: false
    radio: data.radio
    sizeTips: if data.sizeTips then data.sizeTips else ''
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 12
    maxSize: 8
    totalItems: 0
    materials: []
    ungroups: [
        name: '全部'
        _id: 'all'
      ,
        name: '未分組'
        _id: 'unclass'
    ]
    activeGroup:
      name: '全部'
      _id: 'all'
    saveState: false

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
    $scope.$watch 'ui.currentPage', findDatas
    findGroups()
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  findDatas = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/material?access_token=" + Storage.get('access_token'),
      params:
        type: 'image'
        material_group_id: $scope.ui.activeGroup._id
        user_group_id: $scope.ui.user.group_id
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      $scope.ui.totalItems = res.data.count
      $scope.ui.materials = res.data.data
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 查詢素材分組
  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/material/group?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        material_group_id: $scope.ui.activeGroup._id
        type: 'image'
        limit: 100
    .then (res) ->
      $scope.ui.ungroups[0].material_count = res.data.total
      $scope.ui.ungroups[1].material_count = res.data.no_group
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 選中分組
  $scope.setActiveGroup = (group) ->
    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  $scope.setSelect = (image) ->
    image.isSelect = !image.isSelect
    if $scope.ui.radio is true
      _.each $scope.ui.materials, (material) ->
        if material._id isnt image._id then material.isSelect = false

  $scope.uploaderClick = ->
    material_group_id = if $scope.ui.activeGroup._id is 'all' or $scope.ui.activeGroup._id is 'unclass' then '0' else $scope.ui.activeGroup._id
    waterMark_str = if $scope.ui.waterMark is true then $scope.ui.wechat.name else ''
    $scope.uploader.url = "#{config.api.baseUrl}/material/fileupload?access_token=#{Storage.get('access_token')}&user_group_id=#{Storage.get('login_user').group_id}&wechat_id=#{$scope.ui.wechat._id}&type=image&material_group_id=#{material_group_id}&waterMark_str=#{waterMark_str}"

  uploader = $scope.uploader = new FileUploader(
    autoUpload: true
    removeAfterUpload: true
    url: ""
  )
  # FILTERS
  uploader.filters.push
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 2
        growl.error "不能上傳大於2MB的圖片！"
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onBeforeUploadItem = (item) ->
    growl.success("上傳中！", {referenceId: 1})

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findGroups()
    findDatas()

  uploader.onErrorItem = (fileItem, response, status, headers) ->

  getMaterialId = (_id, _wechatId, callback) ->
    $http.post "#{config.api.baseUrl}/material/article/coverImg/materialId?access_token=" + Storage.get('access_token'),
      wechat_id: _wechatId
      material_id: _id
      is_wm: $scope.ui.waterMark
    .then (res) ->
      callback(res.data)
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      callback('err')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $scope.ui.saveState = true

    materials = _.where($scope.ui.materials, {isSelect: true})

    if materials.length < 1
      $scope.ui.saveState = false
      growl.error("請至少選擇一張圖片")
      return

    getMaterialId(materials[0]._id, $scope.ui.wechat._id, (_data) ->
      return $scope.ui.saveState = false if _data == 'err'

      materials[0].file.media_id = _data.media_id
      materials[0].file.url = _data.path
      $uibModalInstance.close materials
    )

angular.module 'app'
.controller 'AddDefaultCtrl', ($scope, $http, $state, $uibModalInstance, $stateParams, config, Storage, dialogs, growl, data, FileUploader, $rootScope, SweetAlert) ->

  $scope.ui =
    isLoading: false
    appmsg: data.appmsg
    titleStatus: false
    questionStatus: false
    firstKeywords: []
    keywords: []
    keysLength: 0
    keyword: ''
    question: ''
    questions: []
    questionsLength: 0
    title: ''

  if data.appmsg
    if data.appmsg.title
      $scope.ui.title = data.appmsg.title.answer
      $scope.ui.titleStatus = true if data.appmsg.title.answer
    $scope.ui.questionStatus = true if data.appmsg.questions.length > 0 || data.appmsg.keys.length > 0
    _.each data.appmsg.questions, (data) ->
      $scope.ui.questions.push({question: data.question, del: data.del})
    _.each data.appmsg.keys, (data) ->
      $scope.ui.keywords.push({key: data.question, del: data.del})

  #主關鍵字
  do fetchFirst = ->
    $http.get "#{config.api.baseUrl}/dsi/qa-keyword?first=",
      params:
        is_using: true
    .then (res) ->
      _.each res.data.keywords, (key) ->
        $scope.ui.firstKeywords.push({key: key, del: false})
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  do findDatas = ->
    $http.get "#{config.api.baseUrl}/dsi/qa?access_token=" + Storage.get('access_token'),
      params:
        limit: 10000
        is_default: false
    .then (res) ->
      $scope.ui.appmsgs = res.data.data
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.save = ->
    return growl.error "請輸入回覆文字" if $scope.ui.titleStatus && !$scope.ui.title
    return growl.error "請選擇主關鍵字/問題" if $scope.ui.questionStatus && $scope.ui.questions.length == 0 && $scope.ui.keywords.length == 0
    return growl.error "主關鍵字/問題添加總數不能大於10" if $scope.ui.questions.length + $scope.ui.keywords.length > 10
    _keywords = []
    _questions = []
    _.each $scope.ui.keywords, (key) ->
      _keywords.push key.key
    _.each $scope.ui.questions, (key) ->
      _questions.push key.question
    $scope.ui.title = '' if !$scope.ui.titleStatus
    if !$scope.ui.questionStatus
      _questions = []
      _keywords = []
    $http.post "#{config.api.baseUrl}/dsi/qa/set-default?access_token=" + Storage.get('access_token'),
      questions: _questions
      keys: _keywords
      title: $scope.ui.title
    .then (res) ->
      growl.success '修改成功'
      $uibModalInstance.close 'Save'
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.addKeyword = ->
    return growl.error "請選擇主關鍵字" if !$scope.ui.keyword
    _keyword = _.filter $scope.ui.keywords, (keyword) ->
      return $scope.ui.keyword == keyword.key
    if _keyword.length == 0
      $scope.ui.keywords.push({key: $scope.ui.keyword, del: false})
    else
      growl.error "已存在"
    $scope.ui.keysLength = $scope.ui.keywords.length
  
  $scope.deleteKeyword = (keyword) ->
    $scope.ui.keywords = _.filter $scope.ui.keywords, (data) ->
      return data if data != keyword

  $scope.addQuestion = ->
    return growl.error "請選擇主關鍵字" if !$scope.ui.question
    _question = _.filter $scope.ui.questions, (question) ->
      return $scope.ui.question == question.question
    if _question.length == 0
      $scope.ui.questions.push({question: $scope.ui.question, del: false})
    else
      growl.error "已存在"
    $scope.ui.questionsLength = $scope.ui.questions.length
  
  $scope.deleteQuestion = (question) ->
    $scope.ui.questions = _.filter $scope.ui.questions, (data) ->
      return data if data != question

  $scope.$watch "ui.questions", (newValue, oldValue) ->
    $scope.ui.questionsLength = newValue.length

  $scope.$watch "ui.keywords", (newValue, oldValue) ->
    $scope.ui.keysLength = newValue.length


angular.module 'app'
.controller 'RebuildModelCtrl', ($rootScope, $timeout, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert, $uibModalInstance, data) ->

  $scope.ui =
    thisUrl: window.location.origin
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 8
    maxSize: 6
    totalItems: 0
    step: 0
    selected: ''
    qa: ''
    choseTesters: data.choseTesters
    last_rebuild_time: data.last_rebuild_time
    rebuild: data.rebuild
    is_checked: data.is_checked

    isSelectAll: false
    fans: []
    choseFans: []
    ungroups: [
        name: '全部'
        tag_id: 'all'
        tag_count: 0
    ]
    activeGroup:
      name: '全部'
      tag_id: 'all'
    groups: []
    search:
      nickname: null
    tipText: data.text
    checkData: {}
    rebuildStatus: false

  if data.qa
    $scope.ui.qa = data.qa
  if data.synonym
    $scope.ui.qa = data.synonym

  findTesters = ->
    $scope.ui.isLoading = true
    $scope.ui.choseFans = []
    $http.get "#{config.api.baseUrl}/dsi/qa-tester?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.choseTesters = res.data.is_add
      # _.each $scope.ui.fans, (fan) ->
      #   _.each res.data.is_add, (tester) ->
      #     if fan.openid is tester.openid
      #       fan.isChecked = true
      #       $scope.ui.choseFans.push tester.openid
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'), {
      params:
        limit: 99
    }
    .then (res) ->
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findFans = ->
    $scope.ui.isLoading = true
    $scope.ui.isSelectAll = false
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/infors/#{$scope.ui.activeGroup.tag_id}?access_token=" + Storage.get('access_token'),
      params:
        nickname: $scope.ui.search.nickname
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      $scope.ui.ungroups[0].tag_count = res.data.count if $scope.ui.ungroups[0].tag_count < 1
      $scope.ui.totalItems = res.data.count
      $scope.ui.fans = res.data.data
      _.each $scope.ui.fans, (fan) ->
        fan.group_name = $scope.ui.activeGroup.name
        fan.isChecked = false
        fan.isChecked = true if _.contains $scope.ui.choseFans, fan.openid
        fan.subscribe_time = moment(fan.subscribe_time).format('YYYY-MM-DD')
        fan.tags = []
        _.each fan.tagid_list, (id) ->
          _.each $scope.ui.groups, (group) ->
            if String(id) is group.tag_id
              fan.tags.push group.name
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
  if $scope.ui.is_checked == false
    $scope.ui.step = 4
  if $scope.ui.rebuild.test == false && $scope.ui.rebuild.prod == true && $scope.ui.choseTesters.length == 0
    $scope.ui.step = 1
    findGroups()
    findFans()

  if $scope.ui.rebuild.test == false && $scope.ui.rebuild.prod == true && $scope.ui.choseTesters.length > 0
    $scope.ui.step = 2
    findTesters()

  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.ui.totalItems = 0
      $scope.ui.fans = []
      $scope.ui.currentPage = 1
      findFans()

  $scope.selectAll = ->
    if $scope.ui.isSelectAll == true
      _.each $scope.ui.fans, (fan) ->
        if fan.isChecked == false
          fan.isChecked = true
          $scope.ui.choseFans.push fan.openid
    else
      _.each $scope.ui.fans, (fan) ->
        if fan.isChecked == true
          fan.isChecked = false
          $scope.ui.choseFans = _.filter $scope.ui.choseFans, (openid) ->
            openid if fan.openid isnt openid

  $scope.selectSingle = (fan) ->
    if fan.isChecked == true
      $scope.ui.choseFans.push fan.openid
    else
      $scope.ui.choseFans = _.filter $scope.ui.choseFans, (openid) ->
        openid if fan.openid isnt openid

  $scope.deleteSelect = ->
    $scope.ui.choseFans = []
    $scope.ui.tipText = ''
    $scope.ui.isSelectAll = false
    _.each $scope.ui.fans, (fan) ->
      fan.isChecked = false

  $scope.search = ->
    $scope.ui.totalItems = 0
    $scope.ui.fans = []
    $scope.ui.currentPage = 1
    findFans()

  $scope.setActiveGroup = (group) ->
    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.fans = []
    $scope.ui.currentPage = 1
    findFans()

  $scope.last = ->
    $scope.ui.step = 1
    $scope.ui.currentPage = 1
    $scope.ui.search.nickname = ''
    findGroups()
    findTesters()
    findFans()

  $scope.last2 = ->
    $scope.ui.step = 2
    findTesters()

  $scope.rebuild = ->
    $scope.ui.rebuildStatus = true
    $http.post "#{config.api.baseUrl}/dsi/qa/build?access_token=" + Storage.get('access_token'),
      type: "test"
    .then (res) ->
      growl.success '更新模型成功'
      $scope.ui.step = 1
      findGroups()
      findTesters()
      findFans()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.reRun = ->
    SweetAlert.swal {
      title: "確定正式發佈?"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.post "#{config.api.baseUrl}/dsi/qa/build?access_token=" + Storage.get('access_token'),
          type: 'both'
          original_id: $stateParams.original_id
        .then (res) ->
          growl.success '正式發佈成功'
          $uibModalInstance.close 'save'
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.save = ->
    return growl.error "請選擇粉絲" if $scope.ui.choseFans.length < 1
    $http.post "#{config.api.baseUrl}/dsi/qa-tester?access_token=" + Storage.get("access_token"),
      original_id: $stateParams.original_id
      openids: $scope.ui.choseFans
    .then (res) ->
      growl.success '保存成功'
      $scope.ui.step = 2
      findTesters()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'cancel'


  $scope.next = ->
    $scope.ui.step = 3

  $scope.checkNext = ->
    $scope.ui.step = 0

  $scope.check = ->
    $http.post "#{config.api.baseUrl}/dsi/qa/check?access_token=" + Storage.get("access_token"),
      access_token: Storage.get('access_token')
    .then (res) ->
      $scope.ui.checkData.Total_Row = res.data[0].Total_Row
      $scope.ui.checkData.Repeats_Row = res.data[1].Repeats_Row
      $scope.ui.checkData.Error_Row = res.data[2].Error_Row
      $scope.ui.checkData.check = true
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
  # findTesters()
  $scope.$watch 'ui.currentPage', findFans
angular.module 'app'
.controller 'ListTemplateCtrl', ($rootScope, $scope, $http, $state, $stateParams, config, Storage, dialogs, growl,$timeout) ->

  $scope.ui =
    isLoading: false
    user: Storage.get('login_user')
    list_id: []
    publishLists: []
    _id: ''
    sortShow: true
    cancel: false
    disabled: true
    editId: ''
    default_name: ''
    resourcesUrl: config.api.baseUrl
    display: config.api.display

  #修改名稱
  $scope.groupPopover =
    isOpen: false
    title: "請填寫專題名稱"
    name: $scope.ui.default_name
    templateUrl: 'groupPopoverTemplate.html'
    open: ->
      $scope.groupPopover.isOpen = true
    close: ->
      $scope.groupPopover.isOpen = false
    save: ->
      $scope.ui.default_name = $scope.groupPopover.modify_name
      $scope.groupPopover.isOpen = false


  fetchWechat = ->
    $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.default_name = res.data.data[0].name if res.data.count > 0

  # 通过_id查找到相关数据
  findData = ->
    $http.get "#{config.api.baseUrl}/homepage/getOne/#{$stateParams._id}?access_token=" + Storage.get('access_token'),
      params: {}
    .then (res) ->
      $scope.ui.publishLists = res.data.list
      $scope.ui.default_name = res.data.name
      _.each res.data.list, (data) ->
        data.url =  data.redirect if data.redirect
        $scope.ui.list_id.push {id: data.sent_id, url: data.redirect}
        $scope.groupPopover.name = res.data.name

  ### 初始化 ###
  if $stateParams._id
    $scope.ui.editId = $stateParams._id
    findData()
  else
    fetchWechat()

  # 列表--示例
  $scope.ui.listExamples = [
    title: '標題示例'
    desc: '摘要示例'
   ,
    title: '標題示例'
    desc: '摘要示例'
  ]

  ### 生成随机字符 ###
  buildRandom = (length) ->
    charts = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    i = 0
    _id = ''
    while i < length
      _id += charts.charAt(Math.floor(Math.random() * charts.length))
      i++
    return buildRandom($scope.ui.randomLength) if _.contains($scope.ui.articleId, _id) == true
    return _id

  ### 線上採集 ###
  $scope.selectGraphicUrl = ->
    dlg = dialogs.create('apps/material/graphic/modal-url/modal-url', 'GraphicUrlModalCtrl', {}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      dialogs.wait '請稍後', '正在採集圖文信息...'
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/collect/url?access_token=" + Storage.get('access_token'),
        url: option
        is_coverimg: true
      .then (res) ->
        new_list =
          sent_id: "ers_#{buildRandom(20)}"
          title: res.data.title
          # digest: $("<div>#{res.data.content}</div>").text().trim().slice(0, 54)
          digest: ""
          thumb_url: res.data.cover_pic.wechat_url
          redirect: option
          url: option
        $scope.ui.publishLists.push(new_list)
        $scope.ui.list_id.push(
          id: new_list.sent_id
          url: option
        )
        $rootScope.$broadcast 'dialogs.wait.complete'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), ->

  # 添加
  $scope.add = ->
    dlg = dialogs.create('apps/page-template/add/addList-template', 'AddListTemplateCtrl', {lists: $scope.ui.publishLists, list_ids: $scope.ui.list_id, appmsg: reply_state: 4}, {backdrop: 'static', size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.publishLists = option.lists
      $scope.ui.list_id = option.list_ids
    ), ->

  # 删除
  $scope.deletePublish = (publish) ->
    $scope.ui.publishLists = _.filter($scope.ui.publishLists, (d) -> d.sent_id isnt publish.sent_id)
    $scope.ui.list_id = _.filter($scope.ui.list_id,(d) -> d.id isnt publish.sent_id)


  $scope.back = ->
    $state.go('app.pagetemplate')

  #發佈
  $scope.release = ->
    return growl.error "請保存排序" if $scope.ui.cancel
    return growl.error('請至少選擇一篇文章') if $scope.ui.list_id.length == 0
    if $stateParams._id
      $http.put "#{config.api.baseUrl}/homepage/update/#{$stateParams._id}?access_token=" + Storage.get('access_token'),
        list: $scope.ui.list_id
        name: $scope.ui.default_name
        publishLists: $scope.ui.publishLists
      .then (res) ->
        growl.success "發佈成功！"
      .finally ->
        $state.go('app.pagetemplate')
        $scope.ui.isLoading = false
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    else
      $http.post "#{config.api.baseUrl}/homepage/add/?access_token=" + Storage.get('access_token'),
        list: $scope.ui.list_id
        original_id: $stateParams.original_id
        name: $scope.ui.default_name
        publishLists: $scope.ui.publishLists
      .then (res) ->
        growl.success "發佈成功！"
      .finally ->
        $state.go('app.pagetemplate')
        $scope.ui.isLoading = false
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  # 開啟排序功能
  $scope.sortt = ->
    $scope.ui.sortShow = false
    $scope.ui.cancel = true

  # 排序（拖拽移動）
  $scope.sortableOptions =
    update: (e, ui) ->
      $timeout ->
        resArr = []
        i = 0
        while i < $scope.ui.publishLists.length
          resArr.push {id: $scope.ui.publishLists[i].sent_id, url: $scope.ui.publishLists[i].url}
          i++
        $scope.ui.list_id = resArr
        return
      return
    stop: (e, ui) ->
      return

  # 取消排序
  $scope.tocancel = ->
    $scope.ui.sortShow = true
    $scope.ui.cancel = false


  $scope.jumpLink = (publish) ->
    dlg = dialogs.create('apps/page-template/jumpLink/jumpLink', 'AddJumpLinkCtrl', {url: publish.url}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option is 'Cancel'
      publish.url = option
      _.each $scope.ui.list_id, (val) ->
        val.url = option if val.id is publish.sent_id
    ), ->

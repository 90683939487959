angular.module 'app'
.controller 'TemplateRecordCtrl', ($scope, $http, $stateParams, $state, config, Storage, $sce, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').issue.template.templateRecord.is_use == false

  $scope.ui =
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    dataArr: []
    activeStatus: ''
    date:
      startDate: moment().subtract(7, 'days')
      endDate: moment()
    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#view-wechat-monitor'
  $scope.ui.opts.opens = 'left'

  # $scope.htmlPopover = $sce.trustAsHtml('<div class="help-popover" style="width: 300px;color:#555;"><dd>1、重發次數增加到3次</dd><dd>2、提示信息修改為：報告第一輪發送的用時，最終成功人數，失敗人數</dd><dd>3、增加一個手動觸發按鈕，可以對最後那一批失敗的人進行重發</dd></div>')

  $scope.findData = ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(7, 'days')
        endDate: moment()
      growl.error('請輸入正確的時間')
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/task/getTaskInfo?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        start_send_at: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_send_at: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
        status: $scope.ui.activeStatus
        # all_done: if $scope.ui.activeStatus == 'sending' then false else undefined
        # status: if $scope.ui.activeStatus == 'sending' then 'issue' else $scope.ui.activeStatus
    .then (res) ->
      update_msg = false
      update_ids = []
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
        data.send_at = moment(data.send_at).format('YYYY-MM-DD HH:mm:ss')
        data.send_typeText = '即時發佈' if data.send_type == 'immediately'
        data.send_typeText = '定時發佈' if data.send_type == 'timing'
        data.send_typeText = '自動發送' if data.send_type == 'auto'
        data.statuText = '等待審核' if data.status == 'create'
        data.statuText = '審核通過' if data.status == 'pass'
        data.statuText = '審核駁回' if data.status == 'reject'
        data.statuText = '發送成功' if data.status == 'issue'
        # SMG 自動發送
        if data.status == 'auto'
          update_msg = true
          update_ids.push data.msgid[0] if data.msgid.length > 0
        data.statuText = '發送成功' if data.status == 'success'
        data.statuText = '發送失敗 ' + data.error_number + ' 人' if data.status == 'failure'
        data.statuText = '等待發送' if data.status == 'auto'
        #
        data.statuText = '發送失敗 ' + data.error_number + ' 人' if data.status == 'no_issue'
        # data.statuText = '發送中' if data.all_done == false && data.status == 'issue'
        data.send_to.tag_id = "無標籤用戶" if data.send_to.tag_id and data.send_to.tag_id == "ers_notag"
        data.tip = ''
        html_str = ''
        if data.template.content != undefined
          html_str = data.template.content.replace(new RegExp('\n','g'),'<br>')

          _.each data.data, (obj, name) ->
            obj.color = '#173177' if obj.color == ''
            html_str = html_str.replace(new RegExp('{{' + name + '.DATA}}','g'), '<span style="color:' + obj.color + ';">' + obj.value.replace(new RegExp('\n','g'),'<br>') + '</span>')

        data.tip = $sce.trustAsHtml '<h4 style="color:#555;">' + data.name + '</h4><div class="margin-bottom-md">' + data.created_at + '</div><div style="font-size:13px;">' + html_str + '</div>'

      $scope.ui.dataArr = res.data.data
      $scope.ui.totalItems = res.data.count

      if update_msg
        baseUrl = config.api.baseUrl.replace('http', 'https')
        $http.post "#{baseUrl}/smg/interface/update-record?access_token=" + Storage.get('access_token'),
          msg_ids: update_ids
          original_id: $stateParams.original_id
        .then (res) ->
          $scope.findData()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.delete = (_data) ->
    $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/delete/#{_data._id}?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      $scope.findData()
      growl.success "刪除成功！"
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  ### 重發 ###
  # $scope.Resend = (data_id) ->
  #   $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/task/resend/#{data_id._id}?access_token=#{Storage.get('access_token')}"
  #   .then (res) ->
  #     $scope.findData()
  #     growl.success "重發成功！"
  #   .catch (error) ->
  #     if error.data.error.indexOf('errtext') >= 0
  #       err = JSON.parse error.data.error
  #       growl.error err.errtext
  #     else
  #       growl.error('網絡連接失敗，請稍後重試')

  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.ui.totalItems = 0
      $scope.ui.dataArr = []
      $scope.ui.currentPage = 1
      $scope.findData()

  $scope.$watch 'ui.currentPage', $scope.findData

  $scope.downloadCsv = ->
    myparams =
      start_send_at: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
      end_send_at: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
      status: $scope.ui.activeStatus

    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/task/downloadTask?access_token=" + Storage.get('access_token'),
      httpMethod: "POST"
      data: myparams


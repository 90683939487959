angular.module 'app'
.controller 'CoverTemplateCtrl', ($rootScope, $scope, $http, $state, $stateParams, config, Storage, dialogs,growl, $timeout, SweetAlert) ->

  $scope.ui =
    isLoading: false
    user: Storage.get('login_user')
    listCover_id: []
    Covershow: true
    publishCoverLists: []
    publishContentList: []
    coverCancel: false
    coverDisabled: true
    list_id: []
    sortShow: true
    lists: []
    cancel: false
    disabled: true
    _id: ''
    editId: ''
    default_name: ''
    resourcesUrl: config.api.baseUrl
    display: config.api.display

    category:
      id: ''
      name: '標題'

    categories: []
    randomLength: 16  # 隨機ID長度
    activeIndex: 0  # 當前焦點下標

    editCover: true  # 封面显示
    editSort: false   # 列表隐藏

    externalLink: '' # 外部鏈接

    activeCategory: {} # 正文显示页



  #修改名稱
  $scope.groupPopover =
    isOpen: false
    title: "請填寫專題名稱"
    name: $scope.ui.default_name
    templateUrl: 'groupPopoverTemplate.html'
    open: ->
      $scope.groupPopover.isOpen = true
    close: ->
      $scope.groupPopover.isOpen = false
    save: ->
      $scope.ui.default_name = $scope.groupPopover.modify_name
      $scope.groupPopover.isOpen = false

  fetchWechat = ->
    $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.default_name = res.data.data[0].name if res.data.count > 0

  # 通过_id查找到相关数据
  findCoverData = ->
    $http.get "#{config.api.baseUrl}/homepage/getOne/#{$stateParams._id}?access_token=" + Storage.get('access_token'),
      params: {}
    .then (res) ->
      res.data.coverList = _.compact(res.data.coverList)
      _.each res.data.coverList, (data) ->
        data.url =  data.redirect if data && data.redirect
        $scope.ui.listCover_id.push {id: data.sent_id, url: data.url}
      res.data.contentList = _.compact(res.data.contentList)
      _.each res.data.contentList, (data) ->
        _.each data.lists, (val) ->
          val.url =  val.redirect if val && val.redirect
      $scope.ui.publishCoverLists = res.data.coverList
      $scope.ui.categories = res.data.contentList
      $scope.ui.activeCategory.lists = res.data.contentList[0].lists
      $scope.ui.activeCategory.id = res.data.contentList[0].id
      $scope.ui.activeCategory.name = res.data.contentList[0].name
      $scope.ui.default_name = res.data.name

  # 生成随机字符
  buildRandom = (length) ->
    charts = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    i = 0
    _id = ''
    while i < length
      _id += charts.charAt(Math.floor(Math.random() * charts.length))
      i++
    findId = _.where $scope.ui.categories, {id: _id}
    return buildRandom($scope.ui.randomLength) if findId.length > 0
    return _id

  addCategory = ->
    Category = angular.copy $scope.ui.category
    Category.id = buildRandom($scope.ui.randomLength)
    Category.lists = []
    $scope.ui.categories.push Category

    $scope.ui.activeCategory = $scope.ui.categories[$scope.ui.categories.length - 1]
    $scope.ui.activeIndex = $scope.ui.categories.length - 1

  # 初始化
  if $stateParams._id
    findCoverData()
  else
    fetchWechat()
    addCategory()

  ### 線上採集 by cover ###
  $scope.selectCoverUrl = ->
    dlg = dialogs.create('apps/material/graphic/modal-url/modal-url', 'GraphicUrlModalCtrl', {}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      dialogs.wait '請稍後', '正在採集圖文信息...'
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/collect/url?access_token=" + Storage.get('access_token'),
        url: option
        is_coverimg: true
      .then (res) ->
        new_list =
          sent_id: "ers_#{buildRandom(20)}"
          title: res.data.title
          # digest: $("<div>#{res.data.content}</div>").text().trim().slice(0, 54)
          digest: ""
          thumb_url: res.data.cover_pic.wechat_url
          redirect: option
          url: option
        $scope.ui.publishCoverLists.push(new_list)
        $scope.ui.listCover_id.push(
          id: new_list.sent_id
          url: option
        )
        $rootScope.$broadcast 'dialogs.wait.complete'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), ->

  # 封面--添加
  $scope.addCover = ->
    dlg = dialogs.create('apps/page-template/add/addCover-template', 'AddCoverTemplateCtrl', { cover_lists: $scope.ui.publishCoverLists, coverLst_ids: $scope.ui.listCover_id, appmsg: reply_state: 4}, {backdrop: 'static', size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.publishCoverLists = option.coverList
      $scope.ui.listCover_id = option.coverListIds
    ), ->

  # 封面--删除
  $scope.deleteCoverPublish = (publish) ->
    $scope.ui.publishCoverLists = _.filter($scope.ui.publishCoverLists, (d) -> d.sent_id isnt publish.sent_id)
    $scope.ui.listCover_id = _.filter($scope.ui.listCover_id,(d) -> d.id isnt publish.sent_id)

  # 開啟封面排序功能
  $scope.sorttCover = ->
    $scope.ui.Covershow = false
    $scope.ui.coverCancel = true

  # 封面排序（拖拽移動）
  $scope.sortableCoverOptions =
    update: (e, ui) ->
      $timeout ->
        resArr = []
        i = 0
        while i < $scope.ui.publishCoverLists.length
          resArr.push {id: $scope.ui.publishCoverLists[i].sent_id, url: $scope.ui.publishCoverLists[i].url}
          i++
        $scope.ui.listCover_id = resArr
        return
      return
    stop: (e, ui) ->
      return

  # 取消封面排序
  $scope.toCovercancel = ->
    $scope.ui.Covershow = true
    $scope.ui.coverCancel = false

  # 列表--示例
  $scope.ui.listExamples = [
    title: '標題示例'
    desc: '摘要示例'
   ,
    title: '標題示例'
    desc: '摘要示例'
   ,
    title: '標題示例'
    desc: '摘要示例'
  ]

  ### 線上採集 by content ###
  $scope.selectContentUrl = ->
    dlg = dialogs.create('apps/material/graphic/modal-url/modal-url', 'GraphicUrlModalCtrl', {}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      dialogs.wait '請稍後', '正在採集圖文信息...'
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/collect/url?access_token=" + Storage.get('access_token'),
        url: option
        is_coverimg: true
      .then (res) ->
        new_list =
          sent_id: "ers_#{buildRandom(20)}"
          title: res.data.title
          # digest: $("<div>#{res.data.content}</div>").text().trim().slice(0, 54)
          digest: ""
          thumb_url: res.data.cover_pic.wechat_url
          redirect: option
          url: option
        $scope.ui.activeCategory.lists.push(new_list)
        $scope.ui.list_id.push(
          id: new_list.sent_id
          url: option
        )
        $scope.ui.publishContentList.push(new_list)
        $rootScope.$broadcast 'dialogs.wait.complete'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), ->

  # 列表--添加
  $scope.add = ->
    dlg = dialogs.create('apps/page-template/add/addList-template', 'AddListTemplateCtrl', {lists: $scope.ui.activeCategory.lists, list_ids: $scope.ui.list_id, appmsg: reply_state: 4}, {backdrop: 'static', size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.activeCategory.lists = option.lists
      $scope.ui.list_id = option.list_ids
      updateList = _.findWhere $scope.ui.categories, id: $scope.ui.activeCategory.id
      updateList.lists = option.lists
    ), ->

  # 列表--删除
  $scope.deleteArticleBottom = (article,i) ->
    $scope.ui.activeCategory.lists = _.filter($scope.ui.activeCategory.lists, (d) -> d.sent_id isnt article.sent_id)
    deleteList = _.findWhere $scope.ui.categories, id: $scope.ui.activeCategory.id
    deleteList.lists = $scope.ui.activeCategory.lists


  # 開啟列表排序功能
  $scope.sortt = ->
    $scope.ui.sortShow = false
    $scope.ui.cancel = true

  # 編輯封面
  $scope.editSort = ->
    return growl.error "請保存排序" if $scope.ui.coverCancel
    $scope.ui.editCover = false
    $scope.ui.editSort = true

  #編輯列表
  $scope.editCover = ->
    return growl.error "請保存排序" if $scope.ui.cancel
    $scope.ui.editCover = true
    $scope.ui.editSort = false

  # 列表排序（拖拽移動）
  $scope.sortableListOptions =
    update: (e, ui) ->
      $timeout ->
        resArr = []
        i = 0
        while i < $scope.ui.activeCategory.lists.length
          resArr.push {id: $scope.ui.activeCategory.lists[i].sent_id, url: $scope.ui.activeCategory.lists[i].url}
          # resArr.push $scope.ui.activeCategory.lists[i].sent_id
          i++
        $scope.ui.list_id = resArr
        _index = _.findIndex $scope.ui.categories, (category) -> category.id == $scope.ui.activeCategory.id
        $scope.ui.categories[_index] = $scope.ui.activeCategory
        return
      return
    stop: (e, ui) ->
      return

  # 取消列表排序
  $scope.tocancel = ->
    $scope.ui.sortShow = true
    $scope.ui.cancel = false

  $scope.back = ->
    $state.go('app.pagetemplate')

  #發佈
  $scope.release = ->
    return growl.error "請保存排序" if $scope.ui.coverCancel || $scope.ui.cancel
    _newContent = []
    listIsEmpty = false
    nameIsEmpty = false
    _.each $scope.ui.categories, (val) ->
      contList =
        name: val.name
        id: val.id
        lists: []
      nameIsEmpty = true if contList.name.length == 0
      val.lists = _.compact(val.lists)
      listIsEmpty = true if val.lists.length == 0
      _.each val.lists, (valAriticle) ->
        conUrl =
          id: valAriticle.sent_id
          url: valAriticle.url
        $scope.ui.publishContentList.push valAriticle if valAriticle.sent_id.indexOf("ers") >= 0
        contList.lists.push conUrl
      _newContent.push contList
    if $scope.ui.listCover_id.length == 0
      return growl.error('請至少選擇一篇封面文章')
    if listIsEmpty
      return growl.error('請至少選擇一篇正文文章')
    if nameIsEmpty
      return growl.error('請輸入分類名稱')

    $scope.ui.listCover_id = _.compact($scope.ui.listCover_id)
    _newContent = _.compact(_newContent)
    if $stateParams._id
      $http.put "#{config.api.baseUrl}/homepage/update/#{$stateParams._id}?access_token=" + Storage.get('access_token'),
        name: $scope.ui.default_name
        type: "new_type"
        list:
          coverList: $scope.ui.listCover_id
          contentList: _newContent
        publishLists:
          publishContentList: $scope.ui.publishContentList
          publishCoverList: $scope.ui.publishCoverLists
      .then (res) ->
        growl.success "發佈成功！"
      .finally ->
        $state.go ('app.pagetemplate')
        $scope.ui.isLoading = false
      .catch (error) ->
        if error.data.error.indexOf() >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error "網絡連接失敗，請稍後重試"
    else
      $http.post "#{config.api.baseUrl}/homepage/add/?access_token=" + Storage.get('access_token'),
        original_id: $stateParams.original_id
        name: $scope.ui.default_name
        type: "new_type"
        list:
          coverList: $scope.ui.listCover_id
          contentList: _newContent
        publishLists:
          publishContentList: $scope.ui.publishContentList
          publishCoverList: $scope.ui.publishCoverLists
      .then (res) ->
        growl.success "發佈成功！"
      .finally ->
        $state.go ('app.pagetemplate')
        $scope.ui.isLoading = false
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  # 新增
  $scope.addCategory = ->
    addCategory()

  # 切换焦点
  $scope.setActive = (category, i) ->
    $scope.ui.activeCategory = category
    $scope.ui.activeIndex = i

  # 删除
  $scope.delArtice = (delCategory) ->
    SweetAlert.swal {
      title: "確定刪除此分類?"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $scope.ui.categories = _.reject $scope.ui.categories, (category) ->
          category.id == $scope.ui.activeCategory.id
        $scope.ui.activeCategory = $scope.ui.categories[0]
        $scope.ui.activeIndex = 0

  $scope.jumpLink = (publish) ->
    dlg = dialogs.create('apps/page-template/jumpLink/jumpLink', 'AddJumpLinkCtrl', {url: publish.url}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option is 'Cancel'
      publish.url = option
      _.each $scope.ui.listCover_id, (val) ->
        val.url = option if val.id is publish.sent_id
    ), ->

  $scope.jumpLink2 = (article) ->
    dlg = dialogs.create('apps/page-template/jumpLink/jumpLink', 'AddJumpLinkCtrl', {url: article.url}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option is 'Cancel'
      article.url = option
      _.each $scope.ui.categories, (val) ->
        contList =
          name: val.name
          id: val.id
          lists: []
        _.each val.lists, (valAriticle) ->
          if valAriticle.sent_id is article.sent_id
            valAriticle.url = option
    ), ->

  $scope.$watch 'ui.activeCategory.name', (newValue, oldValue) ->
    _index = _.findIndex $scope.ui.categories, (catrgory) -> $scope.ui.activeCategory.id == catrgory.id
    if newValue != oldValue
      $scope.ui.categories[_index].name = newValue



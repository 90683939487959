angular.module 'app'
.controller 'PublishRecordCtrl', ($uibModalInstance, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert, data) ->

  $scope.ui =
    resourcesUrl: config.resources.baseUrl + '/imgserve?_url='
    isLoading: true
    records: []
    recordLoading: ''
    wechat: {}
    user: Storage.get('login_user')

  ### 获取當前wechat ###
  fetchWechat = ->
    $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        original_id: $stateParams.original_id
    .then (res) ->
      if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
      fetchRecord()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  fetchRecord = ->
    $scope.ui.isLoading = true
    url = "#{config.api.baseUrl}/draft/article/freepublish/record/#{data.material.wechat_id}/#{data.material._id}?access_token=" + Storage.get('access_token')
    url = "#{config.api.baseUrl}/draft/article/freepublish/record/#{ $scope.ui.wechat._id}/#{data.material._id}?access_token=" + Storage.get('access_token') if $scope.ui.wechat
    $http.post url
    .then (res) ->
      $scope.ui.isLoading = false
      $scope.ui.records = res.data.data
      $scope.ui.recordLoading = ''

  fetchWechat()
  # 初始化查詢記錄
  # fetchRecord()

  # 刪除記錄
  $scope.delete = (article_id, index) ->
    $scope.ui.recordLoading = article_id + index
    url = "#{config.api.baseUrl}/draft/article/freepublish/delete/#{data.material.wechat_id}/#{data.material._id}?access_token=" + Storage.get('access_token')
    url = "#{config.api.baseUrl}/draft/article/freepublish/delete/#{$scope.ui.wechat._id}/#{data.material._id}?access_token=" + Storage.get('access_token') if $scope.ui.wechat
    $http.post url,
      article_id: article_id
      index: index + 1
    .then (res) ->
      fetchRecord()
    .catch (error) ->
      $scope.ui.recordLoading = ''
      growl.error('網絡連接失敗，請稍後重試')

  # 複製
  $scope.copyValue = (value) ->
    aux = document.createElement("input")
    aux.setAttribute("value", value)
    document.body.appendChild(aux)
    aux.select()
    document.execCommand("Copy")
    document.body.removeChild(aux)

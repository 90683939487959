angular.module 'app'
.controller 'RecordCtrl', ($rootScope, $scope, $http, $stateParams, $state, config, Storage, growl, dialogs, SweetAlert, $timeout) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').issue.information.informationRecord.is_use == false

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    totalItems: 0
    currentPage: 1
    perPage: 7
    maxSize: 8
    dataArr: []
    activeType: ''
    activeStatus: ''
    date:
      startDate: moment().subtract(7, 'days')
      endDate: moment()
    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#view-wechat-monitor'
  $scope.ui.opts.opens = 'left'

  $scope.findData = ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(7, 'days')
        endDate: moment()
      growl.error('請輸入正確的時間')

    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/send?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        type: $scope.ui.activeType
        status: $scope.ui.activeStatus
        user_id: $scope.ui.user.id
        start_release_time: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_release_time: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
    .then (res) ->
      _.each res.data.data, (data) ->
        if data.status == 'checked' && data.is_send == true && (moment().diff(moment(data.send_time)) / 60000) > 30
          data.is_send = false
          data.status = 'exception'

        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
        data.send_time = moment(data.send_time).format('YYYY-MM-DD HH:mm:ss')

        data.typeText = '文字' if data.type == 'text'
        data.typeText = '圖片' if data.type == 'image'
        data.typeText = '圖文' if data.type == 'news'
        data.typeText = '音頻' if data.type == 'voice'
        data.typeText = '視頻' if data.type == 'video'

        data.send_typeText = '即時發佈' if data.send_type == 'immediately'
        data.send_typeText = '定時發佈' if data.send_type == 'timing'

        data.statuText = '等待審核' if data.status == 'create'
        data.statuText = '審核通過' if data.status == 'checked'
        data.statuText = '審核駁回' if data.status == 'modify'
        data.statuText = '發送成功' if data.status == 'issue'
        data.statuText = '發送失敗' if data.status == 'no_issue'
        data.statuText = '發送異常' if data.status == 'exception'
        data.statuText = '等待官方發佈' if data.status == 'sending'

        if data.content != 'null'
          data.content = JSON.parse(data.content) if data.type != 'text'
          if data.type == 'news'
            _.each data.content.articles, (article) ->
              if config.api.display
                article.content = article.content.replace(/\"http\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\(http\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\&quot;http\:\/\/mmbiz.qpic.cn/g, '&quot;' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\"https\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn').replace(/\(https\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn').replace(/\&quot;https\:\/\/mmbiz.qpic.cn/g, '&quot;' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn')

          data.content.file.thumb = config.api.baseUrl + data.content.file.thumb if data.type == 'video' && data.content.file.thumb != '' && data.content.file.is_official == false

        data.isdeleteAll = _.contains(data.send_delete_article_idxs, '0')
        if data.isdeleteAll
          _.forEach data.content.articles, (article) -> article.isdelete = true
        else
          _.each data.send_delete_article_idxs, (i) ->
            data.content.articles[i - 1]?.isdelete = true
      $scope.ui.dataArr = res.data.data
      $scope.ui.totalItems = res.data.count
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 失敗原因
  $scope.reason = (data) ->
    if data.status == 'no_issue'
      if data.send_callback
        if data.send_callback.copyright_check_result
          data.reason = []
          _.each data.send_callback.copyright_check_result.resultList, (val) ->
            activeIndex = val.articleIdx - 1
            data.reason.push ({title: data.articles[activeIndex].title, originalArticleUrl: val.originalArticleUrl})
          dlg = dialogs.create('apps/information/record-dialogs/record-failreason', 'RecordFailCtrl', {reason: data.reason, error: data.send_callback.error, status: data.send_callback.status}, {size: 'md'})
          dlg.result.then ((option) ->
          ), ->
        else
          _errtext = ''
          if data.send_callback.error_type == 'ers'
            if data.send_callback.error.indexOf('獲取access_token時AppSecret錯誤，或者access_token無效') > -1
              _errtext = '網絡出錯，請重新發佈'
            else
              _errtext = data.send_callback.error
          else
            _errtext = JSON.parse(data.send_callback.error).errtext
          dlg = dialogs.create('apps/information/record-dialogs/record-failreason', 'RecordFailCtrl', {error: _errtext}, {size: 'md'})
          dlg.result.then ((option) ->
          ), ->
      else
        dlg = dialogs.create('apps/information/record-dialogs/record-failreason', 'RecordFailCtrl', {error: '等待微信官方返回結果'}, {size: 'md'})
        dlg.result.then ((option) ->
        ), ->

  $scope.openNews = (_obj, _data) ->
    dlg = dialogs.create('apps/information/message/message', 'InformationMessageCtrl', {content: _obj, childent: _data, type: '1', show: '0'}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findAll()
    ), ->

  $scope.delete = (_data) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除該任務？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/delete/#{_data._id}?access_token=" + Storage.get('access_token'),{}
        .then (res) ->
          $scope.findData()
          growl.success "刪除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  # 下載
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力導出...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.downloadGraphic = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/download?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        type: 'news'
        status: $scope.ui.activeStatus
        start_release_time: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_release_time: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
      prepareCallback: prepare
      successCallback: success
      failCallback: error

  $scope.$watch 'ui.currentPage', $scope.findData

  # 駁回修改
  $scope.editMaterial = (_material) ->
    dlg = dialogs.create('apps/material/graphic/detail/detail', 'GraphicDetailCtrl', {material: _material}, {size: 'lg'})
    dlg.result.then ((option) ->
    ), ->

  # 彈窗
  $scope.adddialogs = (data) ->
    dialogs.create('apps/information/record-dialogs/record-dialogs', 'RecorddialogsCtrl', {recorddata: data}, {size: 'md'})
    .result.then ((option) ->
      $scope.findData()
     ), ->

  # 刪除單篇歷史文章
  # $scope.exchangePopover =
  #   data: null
  #   title: "歷史文章"
  #   templateUrl: 'exchangePopoverTemplate.html'
  #   delete: (index) ->
  #     SweetAlert.swal {
  #       title: "你確定嗎?"
  #       text: "是否確定刪除該歷史文章？"
  #       type: "warning"
  #       showCancelButton: true
  #       confirmButtonClass: 'btn-danger'
  #       cancelButtonText: '取消'
  #       confirmButtonText: '確定'
  #     }, (isConfirm) ->
  #       if isConfirm
  #         $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/delete/#{$scope.exchangePopover.data._id}?access_token=" + Storage.get('access_token') + "&article_idx=#{index}",{}
  #         .then (res) ->
  #           $scope.findData()
  #           growl.success "刪除成功！"
  #         .catch (error) ->
  #           if error.data.error.indexOf('errtext') >= 0
  #             err = JSON.parse error.data.error
  #             growl.error err.errtext
  #           else
  #             growl.error('網絡連接失敗，請稍後重試')

  # $scope.setExchangePopover = (data) ->
  #   $scope.exchangePopover.data = angular.copy data


  # 按下 Enter 鍵查詢
  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.ui.totalItems = 0
      $scope.ui.dataArr = []
      $scope.ui.currentPage = 1
      $scope.findData()

angular.module 'app'
.controller 'DefaultQaCtrl', ($rootScope, $scope, $http, $stateParams, $state, $location, config, Storage, SweetAlert, $timeout, dialogs, growl, FileUploader) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').interaction.smartService.is_use == false

  $scope.ui =
    isLoading: false
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    appmsgs: []
    last_rebuild_time: ''
    choseTesters: []
    firstKeywords: []
    questions: []
    rebuild: false

  findDatas = ->
    $scope.ui.appmsgs = []
    $http.get "#{config.api.baseUrl}/dsi/qa?access_token=" + Storage.get('access_token'),
      params:
        limit: 1000
        is_default: true
    .then (res) ->
      _appmsgs = {}
      _appmsgs.response_no = 0
      _.each res.data.data, (data) ->
        data.updated_at = moment(data.updated_at).format('YYYY-MM-DD HH:mm')
      _title = []
      _title = _.filter res.data.data, (data) ->
        return data.is_title
      _appmsgs.title = _title[0]
      _appmsgs.keys = _.filter res.data.data, (data) ->
        return data.is_default_key
      _.each _appmsgs.keys, (key) ->
        key.del = false
        key.del = true if $scope.ui.firstKeywords.indexOf(key.question) == -1
      _appmsgs.questions = _.filter res.data.data, (data) ->
        return data.is_default_question
      _.each _appmsgs.questions, (question) ->
        question.del = false
        question.del = true if $scope.ui.questions.indexOf(question.question) == -1
      _appmsgs.response_no = _appmsgs.title.response_no if _appmsgs.title.response_no
      _appmsgs.updated_at = _appmsgs.title.updated_at
      $scope.ui.appmsgs.push _appmsgs
      $scope.ui.rebuild = res.data.default_should_rebuild
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  #主關鍵字
  do fetchFirst = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/dsi/qa-keyword?first=",
      params:
        is_using: true
    .then (res) ->
      $scope.ui.firstKeywords = res.data.keywords
      findquestions()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findquestions = ->
    $http.get "#{config.api.baseUrl}/dsi/qa?access_token=" + Storage.get('access_token'),
      params:
        limit: 10000
        is_default: false
    .then (res) ->
      _.each res.data.data, (data) ->
        $scope.ui.questions.push data.question
      findDatas()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')


  do findTesters = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/dsi/qa-tester?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.choseTesters = res.data.is_add
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  #修改
  $scope.editDetail = (appmsg) ->
    dlg = dialogs.create('apps/smartService/add/addDefault', 'AddDefaultCtrl', {appmsg: appmsg, type: 'default'}, {size: 'lg'})
    dlg.result.then ((option) ->
      fetchFirst()
      return if option == 'Cancel'
    ), ->

  $scope.rebuildModel = ->
    return if $scope.ui.rebuild == false
    dlg = dialogs.create('apps/smartService/rebuild/rebuildDefault', 'RebuildDefaultCtrl', {rebuild: $scope.ui.rebuild, choseTesters: $scope.ui.choseTesters}, {backdrop: 'static', size: 'lg'})
    dlg.result.then ((option) ->
      findDatas()
      findTesters()
      return if option == 'Cancel'
    ), ->

    # SweetAlert.swal {
    #   title: "確定發佈？"
    #   text: "發佈將覆蓋原有默認回覆，請確認測試無誤後再發佈"
    #   type: "warning"
    #   showCancelButton: true
    #   confirmButtonClass: 'btn-danger'
    #   cancelButtonText: '取消'
    #   confirmButtonText: '確定'
    # }, (isConfirm) ->
    #   if isConfirm
    #     $http.post "#{config.api.baseUrl}/dsi/qa/build-default?access_token=" + Storage.get('access_token'), {}
    #     .then (res) ->
    #       findDatas()
    #       growl.success '發佈成功'
    #     .catch (error) ->
    #       if error.data.error.indexOf('errtext') >= 0
    #         err = JSON.parse error.data.error
    #         growl.error err.errtext
    #       else
    #         growl.error('網絡連接失敗，請稍後重試')
angular.module 'app'
.controller 'TemplateDetailCtrl', ($scope, $http, $state, $sce, $stateParams, $uibModalInstance, config, Storage, dialogs, growl, data) ->
  $scope.ui =
    user: Storage.get('login_user')
    _id: data._id
    _data: {}
    type: data.type

  do getData = ->
    if $scope.ui.type is "library"
      $http.get "#{config.api.baseUrl}/template/list/#{$scope.ui._id}?access_token=" + Storage.get('access_token'),{}
      .then (res) ->
        if res.status == 200 && res.data != null
          option = res.data.content
          html_str = option.replace(new RegExp('\n','g'),'<br>').split('<br>')
          keys = []

          _.each html_str, (val) ->
            _name = ''
            _key = ''
            _name = val.split('{{')[0]
            _key = val.split('{{')[1].split('.DATA}}')[0] if val.split('{{')[1] != undefined
            keys.push {name: _name, key: _key, val: '', color: '#173177'}

          res.data.content_val = keys
          res.data.example_html = $sce.trustAsHtml res.data.example.replace(new RegExp('\n','g'),'<br>')
          $scope.ui._data = res.data
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    else
      $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/template/#{$scope.ui._id}?access_token=" + Storage.get('access_token'),{}
      .then (res) ->
        if res.status == 200 && res.data != null
          option = res.data.content
          html_str = option.replace(new RegExp('\n','g'),'<br>').split('<br>')
          keys = []

          _.each html_str, (val) ->
            _name = ''
            _key = ''
            _name = val.split('{{')[0]
            _key = val.split('{{')[1].split('.DATA}}')[0] if val.split('{{')[1] != undefined
            keys.push {name: _name, key: _key, val: '', color: '#173177'}

          res.data.content_val = keys
          res.data.example_html = $sce.trustAsHtml res.data.example.replace(new RegExp('\n','g'),'<br>')
          $scope.ui._data = res.data
          $scope.ui._data.template_id_short = res.data.template_id
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  # 套用
  $scope.setValue = ->
    _strs = $scope.ui._data.example.replace(new RegExp('\n','g'),'<br>').split('<br>')

    _.each $scope.ui._data.content_val, (data, i) ->
      if data.name == ''
        data.val = _strs[i]
      else
        data.val = _strs[i].substring(_strs[i].indexOf(data.name) + data.name.length)

  # 编制
  $scope.setExample = ->
    $scope.ui._data.content_edit = angular.copy $scope.ui._data.content_val

    _strs = $scope.ui._data.example.replace(new RegExp('\n','g'),'<br>').split('<br>')

    _.each $scope.ui._data.content_edit, (data, i) ->
      data.color = '#173177'
      if data.name == ''
        data.val = _strs[i]
      else
        data.val = _strs[i].substring(_strs[i].indexOf(data.name) + data.name.length)

  # 保存示例
  $scope.saveExample = ->
    _str = angular.copy $scope.ui._data.content
    _.each $scope.ui._data.content_edit, (data, i) ->
      _str = _str.replace('{{' + data.key + '.DATA}}', data.val)

    $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/template/#{$scope.ui._data._id}?access_token=" + Storage.get('access_token'),
      example: _str
    .then (res) ->
      getData()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')


  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    _str = '{'
    _.each $scope.ui._data.content_val, (key) ->
      _str += key.key + ': {value: "' + key.val + '", color: "' + key.color + '"},' if key.key != ''

    _str += '}'
    str = eval("(" + _str + ")")

    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/template?access_token=" + Storage.get('access_token'),
      template_list_id: $scope.ui._data._id
      data: str
    .then (res) ->
      growl.success('保存成功')
      $uibModalInstance.close 'Save'
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $uibModalInstance.close 'Save'

  $scope.send = ->
    _str = '{'
    _length = $scope.ui._data.content_val.length - 1
    _.each $scope.ui._data.content_val, (key, _index) ->
      key.val = "" if !key.val
      if !key.val && key.key == 'remark'
        key.remarkVal = true
      _val = key.val.replace(/\n/g,'').replace('\r','')
      _val = "" if key.key == 'first'
      _val = "" if key.key == 'remark'
      if _index != _length && key.key != ''
        _str += '"' + key.key + '": {"value": "' + _val + '", "color": "' + key.color + '"},'
      else if _index == _length && key.key != ''
        _str += '"' + key.key + '": {"value": "' + _val + '", "color": "' + key.color + '"}'

    _str += '}'
    str = JSON.parse(_str)
    # str = eval("(" + _str + ")")

    _status = true
    # _.each str, (e) ->
    #   _status = false if e.value == '' && !e.remarkVal
    # return growl.error('請填寫模板內容') if _status == false
    _.each str, (val) ->
      delete val.remarkVal
    dlg = dialogs.create('apps/material/graphic/select/select', 'SelectPeopleCtrl', {text: '選擇人數必須小於等於 10000 人，大於等於 1 人'}, {size: 'md'})
    dlg.result.then ((option) ->
      return $uibModalInstance.close 'Save' if option == 'Cancel'
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/testsend?access_token=" + Storage.get('access_token'),
        template_id: $scope.ui._data._id
        openids: option
        data: str
      .then (res) ->
        growl.success('測試發送成功')
        $uibModalInstance.close 'Save'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $uibModalInstance.close 'Save'
    ), ->

  $scope.setColor = (_index, _color) ->
    $scope.ui._data.content_val[_index].color = _color

angular.module 'app'
.controller 'SelectFansCtrl', ($rootScope, $timeout, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert, $uibModalInstance, data) ->

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 8
    maxSize: 8
    totalItems: 0
    isSelectAll: false
    choseFans: []
    fans: []
    ungroups: [
        name: '全部'
        tag_id: 'all'
        tag_count: 0
    ]
    activeGroup:
      name: '全部'
      tag_id: 'all'
    groups: []
    search:
      nickname: null
    tipText: data.text
    selected: ''
    openid: ''
    menu_id: data.menu_id


  # 查詢粉絲分組
  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'), {
      params:
        limit: 99
    }
    .then (res) ->
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findDatas = ->
    $scope.ui.isLoading = true
    $scope.ui.isSelectAll = false
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/infors/#{$scope.ui.activeGroup.tag_id}?access_token=" + Storage.get('access_token'),
      params:
        nickname: $scope.ui.search.nickname
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      $scope.ui.ungroups[0].tag_count = res.data.count if $scope.ui.ungroups[0].tag_count < 1

      $scope.ui.totalItems = res.data.count
      $scope.ui.fans = res.data.data
      _.each $scope.ui.fans, (fan) ->
        fan.group_name = $scope.ui.activeGroup.name
        fan.isChecked = false
        fan.isChecked = true if _.contains $scope.ui.choseFans, fan.openid
        fan.subscribe_time = moment(fan.subscribe_time).format('YYYY-MM-DD')
        fan.fansLanguage = switch fan.language
          when 'zh_CN' then '簡體中文'
          when 'zh_TW' then '繁体中文TW'
          when 'zh_HK' then '繁体中文HK'
          when 'en' then '英文'
          when 'id' then '印尼'
          when 'ms' then '馬來'
          when 'es' then '西班牙'
          when 'ko' then '韓國'
          when 'it' then '意大利'
          when 'ja' then '日本'
          when 'pl' then '波蘭'
          when 'pt' then '葡萄牙'
          when 'ru' then '俄國'
          when 'th' then '泰文'
          when 'vi' then '越南'
          when 'ar' then '阿拉伯語'
          when 'hi' then '北印度'
          when 'he' then '希伯來'
          when 'tr' then '土耳其'
          when 'de' then '德語'
          when 'fr' then '法語'
          else '其他'
        fan.tags = []
        _.each fan.tagid_list, (id) ->
          _.each $scope.ui.groups, (group) ->
            if String(id) is group.tag_id
              fan.tags.push group.name
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.search = ->
    $scope.ui.totalItems = 0
    $scope.ui.fans = []
    $scope.ui.currentPage = 1
    findDatas()

  # 選中左邊標簽
  $scope.setActiveGroup = (group) ->
    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.fans = []
    $scope.ui.currentPage = 1
    findDatas()

  $scope.selectSingle = (data) ->
    $scope.ui.fansData = data
    $scope.ui.selected = data._id
    $scope.ui.openid = data.openid

  $scope.isChecked = (data) ->
    if data._id == $scope.ui.selected
      return true
    else
      return false

  $scope.deleteSelect = ->
    $scope.ui.selected = ''

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    return growl.error "請選擇粉絲" if $scope.ui.selected == ''
    $uibModalInstance.close {
      openid: $scope.ui.openid
      menu_id: $scope.ui.menu_id
  }

  findGroups()
  $scope.$watch 'ui.currentPage', findDatas

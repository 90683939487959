angular.module 'app'
.controller 'QrcodeListCtrl', ($scope, $http, $stateParams, $state, $rootScope, config, Storage, SweetAlert, dialogs, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').interaction.qrcode.is_use == false

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    qrcode_name: ""
    isLoading: false
    isGroupLoading: false
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    qrcodes: []
    qrcodeList: []
    group_id: 'all'
    activeGroup:
      name: '全部'
      _id: 'all'
    ungroups: [
      name: '全部'
      _id: 'all'
      qrcode_count: 0
    ]
    delTemplate: false
    delTemplateId: []
    isSelectAll: false

    tagsPopoverDisabled: false
    newGroupDisabled: false
    editGroupDisabled: false
    editFansRemarkDisabled: false

    # 新增分組
  $scope.groupPopover =
    name: ''
    isOpen: false
    title: "請填寫分組名稱"
    templateUrl: 'groupPopoverTemplate.html'
    open: ->
      $scope.groupPopover.isOpen = true
      $scope.ui.newGroupDisabled = false
    close: ->
      $scope.groupPopover.isOpen = false
      $scope.ui.newGroupDisabled = false
    save: ->
      dialogs.wait '請稍後', '正在處理...'
      $scope.ui.newGroupDisabled = true
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/group?access_token=" + Storage.get('access_token'),
        name: $scope.groupPopover.name
        user_group_id: $scope.ui.user.group_id
      .then (res) ->
        findGroups()
        growl.success "保存成功！"
        $scope.groupPopover.isOpen = false
        $scope.groupPopover.name = ''
        $scope.ui.newGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.newGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'

  # 修改分組名
  $scope.editGroupPopover =
    isOpen: false
    title: "修改分組"
    name: ''
    templateUrl: 'editGroupPopoverTemplate.html'
    open: ->
      $scope.editGroupPopover.isOpen = true
      $scope.ui.editGroupDisabled = false
    close: ->
      $scope.editGroupPopover.isOpen = false
      $scope.ui.editGroupDisabled = false
    save: ->
      dialogs.wait '請稍後', '正在處理...'
      $scope.ui.editGroupDisabled = true
      $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/group/#{$scope.ui.activeGroup._id}?access_token=" + Storage.get('access_token'),
        name: $scope.editGroupPopover.name
      .then (res) ->
        $scope.ui.activeGroup.name = $scope.editGroupPopover.name
        _.each $scope.ui.groups, (group) ->
          if group._id is $scope.ui.activeGroup._id
            group.name = $scope.editGroupPopover.name
        growl.success "保存成功！"
        $scope.editGroupPopover.isOpen = false
        $scope.ui.editGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.editGroupDisabled = false
        $rootScope.$broadcast 'dialogs.wait.complete'

  # 保存分組
  $scope.tagsPopover =
    title: "加入分組"
    templateUrl: 'tagsPopoverTemplate.html'
    isOpen: false
    open: ->
      $scope.tagsPopover.isOpen = true
      $scope.ui.tagsPopoverDisabled = false
    close: ->
      $scope.tagsPopover.isOpen = false
      $scope.ui.tagsPopoverDisabled = false
    save: ->
      return growl.error "請選擇二維碼" if $scope.ui.qrcodeList.length < 1

      $scope.ui.tagsPopoverDisabled = true

      dialogs.wait '請稍後', '正在處理...'
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/members_group?access_token=" + Storage.get('access_token'),
        qrcode_list: $scope.ui.qrcodeList
        tagid: $scope.ui.group_id
      .then (res) ->
        $rootScope.$broadcast 'dialogs.wait.complete'
        $scope.tagsPopover.isOpen = false
        growl.success "加入分組成功！"

        # 初始化分組list
        $scope.ui.qrcodeList = []

        fetchQrcode()
        findGroups()
      .catch (error) ->
        $rootScope.$broadcast 'dialogs.wait.complete'
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.tagsPopoverDisabled = false
    
  # 刪除分組
  $scope.delGroup = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{$scope.ui.activeGroup.name}”分組？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        dialogs.wait '請稍後', '正在處理...'
        $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/group/#{$scope.ui.activeGroup._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findGroups()

          $scope.ui.activeGroup = $scope.ui.groups[0]
          $scope.ui.totalItems = 0
          $scope.ui.fans = []
          $scope.ui.currentPage = 1
          fetchQrcode()

          growl.success "刪除成功！"
          $rootScope.$broadcast 'dialogs.wait.complete'
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
          $rootScope.$broadcast 'dialogs.wait.complete'

  # 選中左邊標簽
  $scope.setActiveGroup = (group) ->
    $scope.ui.qrcode_name = ''

    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.qrcodes = []
    $scope.ui.currentPage = 1
    fetchQrcode()

  $scope.setTagsPopover = ->
    $scope.ui.tagsPopoverDisabled = false
    $scope.tagsPopover.groups = angular.copy $scope.ui.groups
    _.each $scope.tagsPopover.groups, (val) ->
      val.name = "未分組" if val._id == 'all'
      val.isChecked = false

  $scope.search = ->
    $scope.ui.totalItems = 0
    $scope.ui.qrcodes = []
    $scope.ui.currentPage = 1
    fetchQrcode()

  # 查詢分組分組
  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/group?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      all = _.findWhere $scope.ui.ungroups, name: '全部'
      all.qrcode_count = res.data.total
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  fetchQrcode = ->
    $scope.ui.isLoading = true
    $scope.ui.isSelectAll = false
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew?access_token=" + Storage.get('access_token'),
      params:
        qrcode_name: $scope.ui.qrcode_name
        qrcode_group_id: $scope.ui.activeGroup._id
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      _.each res.data.data, (val) ->
        isSelect = false
        _.each $scope.ui.qrcodeList, (qrcode) ->
          isSelect = true if qrcode == val._id
        val.isChecked = isSelect
        val.timeout_date = if val.type is "QR_LIMIT_STR_SCENE" then "永久" else moment(val.timeout_date).format('YYYY-MM-DD HH:mm')
        if val.is_action
          if val.rule.sexs.length > 1
            val.rule.sex_name = "全部"
          else if val.rule.sexs[0] == "1"
            val.rule.sex_name = "男性"
          else if val.rule.sexs[0] == "2"
            val.rule.sex_name = "女性"
          else
            val.rule.sex_name = "未知"

          val.rule.language_name = []
          if val.rule.languages.length > 3 or _.contains val.rule.languages, "all"
            val.rule.language_name.push "全部"
          else
            _.each val.rule.languages, (data) ->
              data = "簡體中文" if data == "zh_CN"
              data = "繁體中文" if data == "zh_TW"
              data = "英文" if data == "en"
              data = "" if data == "zh_HK"
              val.rule.language_name.push data
        else
          val.rule = {}
          val.rule.sex_name = "-"
          val.rule.language_name = "-"
      $scope.ui.qrcodes = res.data.data
      $scope.ui.totalItems = res.data.count
      $scope.ui.isLoading = false
      findTemplate()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  findGroups()
  $scope.$watch 'ui.currentPage', fetchQrcode

  $scope.selectAll = ->
    if $scope.ui.isSelectAll == true
      _.each $scope.ui.qrcodes, (qrcode) ->
        if qrcode.isChecked == false
          qrcode.isChecked = true
          $scope.ui.qrcodeList.push qrcode._id
    else
      _.each $scope.ui.qrcodes, (qrcode) ->
        if qrcode.isChecked == true
          qrcode.isChecked = false
          $scope.ui.qrcodeList = _.filter $scope.ui.qrcodeList, (qrcodeId) ->
            qrcodeId if qrcode._id isnt qrcodeId

  $scope.selectSingle = (data) ->
    if data.isChecked == true
      $scope.ui.qrcodeList.push data._id
    else
      $scope.ui.qrcodeList = _.filter $scope.ui.qrcodeList, (qrcode) ->
        qrcode if data._id isnt qrcode

  findTemplate = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/template?access_token=" + Storage.get('access_token'),
      params:
        limit: 1000
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      _template_id = []
      _template_id = _.pluck(res.data.data, 'template_id')
      _.each $scope.ui.qrcodes, (data) ->
        if data.action.reply_type == 'template'
          if !_.contains(_template_id, data.action.template_id)
            data.action.delTemplate = true
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  # 添加二维码
  $scope.add = ->
    dlg = dialogs.create('apps/qrcode/add/add', 'QrcodeAddCtrl', {qrcode_group_id: $scope.ui.activeGroup._id}, {size: 'md'})
    dlg.result.then ((option) ->
      fetchQrcode()
      findGroups()
    ), ->

  # 修改二维码
  $scope.response = (qrcode) ->
    dlg = dialogs.create('apps/qrcode/response/response', 'QrcodeResponseCtrl', {qrcode: qrcode}, {size: 'md'})
    dlg.result.then ((option) ->
      fetchQrcode()
    ), ->

  $scope.delQrcode = (qrcode) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{qrcode.name}”!"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/#{qrcode._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          growl.success "刪除成功！"
          findGroups()
          fetchQrcode()
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.download = (data) ->
    dlg = dialogs.create('apps/qrcode/add/add', 'QrcodeAddCtrl', {data: data}, {size: 'md'})
    dlg.result.then ((option) ->
      fetchQrcode()
    ), ->

  $scope.showPeople = (data) ->
    dlg = dialogs.create('apps/qrcode/modal/qrcode-modal', 'QrcodeModalCtrl', {type: 'qrcode', _id: data._id}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
    ), ->

angular.module 'app'
.controller 'ExamineCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').issue.information.informationExamine.is_use == false

  $scope.ui =
    user: Storage.get('login_user')
    resourcesUrl: config.resources.baseUrl

    activeList: 0
    listArr: []

  findAll = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/send?access_token=" + Storage.get('access_token'),
      params:
        status: 'create'
        user_id: $scope.ui.user.id
    .then (res) ->
      _.each res.data.data, (data) ->
        data.content = JSON.parse data.content if data.type != 'text'
        data.type_text = '文字' if data.type == 'text'
        data.type_text = '圖文' if data.type == 'news'
        data.type_text = '圖片' if data.type == 'image'
        data.type_text = '音頻' if data.type == 'voice'
        data.type_text = '視頻' if data.type == 'video'

        if data.type == 'news' && data.content != null
          _.each data.content.articles, (article) ->
            if config.api.display
              article.content = article.content.replace(/\"http\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\(http\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\&quot;http\:\/\/mmbiz.qpic.cn/g, '&quot;' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\"https\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn').replace(/\(https\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn').replace(/\&quot;https\:\/\/mmbiz.qpic.cn/g, '&quot;' + config.api.baseUrl + '/imgserve?_url=https://mmbiz.qpic.cn')

        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
        data.send_time = moment(data.send_time).format('YYYY-MM-DD HH:mm:ss')
        data.content.file.thumb = config.api.baseUrl + data.content.file.thumb if data.type == 'video' && data.content.file.thumb != '' && data.content.file.is_official == false

      $scope.ui.listArr = res.data.data
      $scope.ui.activeList = -1 if $scope.ui.listArr.length == 0
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 处理审核
  examine = (type, callback) ->
    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/send/#{$scope.ui.listArr[$scope.ui.activeList]._id}?access_token=" + Storage.get('access_token'),
      status: type
    .then (res) ->
      callback res
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      callback error

  # 留言
  $scope.leavingMsg = (_type, _child, _item) ->
    return if $scope.ui.activeList == -1
    _content = $scope.ui.listArr[$scope.ui.activeList]
    _childent = ''
    _content = _item if _type == '1'
    _childent = _child if _type == '1'
    dlg = dialogs.create('apps/information/message/message', 'InformationMessageCtrl', {content: _content, childent: _childent, type: _type, show: '1'}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findAll()
    ), ->

  # 批准
  $scope.approval = ->
    if new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 3) > moment($scope.ui.listArr[$scope.ui.activeList].created_at).toDate()
      _date = moment($scope.ui.listArr[$scope.ui.activeList].created_at).format('YYYY年MM月DD日')
      SweetAlert.swal {
        title: "確定發佈？"
        text: "發佈申請創建日期為：#{_date}"
        type: "warning"
        showCancelButton: true
        confirmButtonClass: 'btn-danger'
        cancelButtonText: '取消'
        confirmButtonText: '確定'
      }, (isConfirm) ->
        if isConfirm
          approvalNew()
    else
      approvalNew()

  approvalNew = ->
    examine('checked', (data) ->
      if data.status == 200
        growl.success('操作成功')
        findAll()
    )

  # 驳回
  $scope.reject = ->
    examine('modify', (data) ->
      if data.status == 200
        growl.success('操作成功')
        findAll()
    )

  $scope.selectList = (_index) ->
    return if $scope.ui.activeList == _index
    $scope.ui.activeList = _index

  findAll()

  $scope.fansChecked = ->
    dlg = dialogs.create('apps/information/examine/selected/fans', 'SelectedArtFansCtrl', {_id: $scope.ui.listArr[$scope.ui.activeList]._id}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
    ), ->
